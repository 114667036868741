import HelpDeskItem from "../../../account/helpDesk/HelpDeskItem";
import {useDispatch, useSelector} from "react-redux";
import {getHelpDeskItems} from "../../../../redux/action/helpDeskActions";


const getHelpDeskItem = (itemID, helpDeskItems) => {
    if(helpDeskItems && !isNaN(itemID)) {
        const compareID = Number(itemID);
        for(const category of helpDeskItems) {
            for(const item of category["web_help_desk_item"]) {
                if(item.id === compareID) {
                    console.log("hi")
                    return item;
                }
            }
        }
    }
}

const AccountLayoutHelpItem = ({itemID}) => {

    const dispatch = useDispatch();
    const { session } = useSelector(
        state => state.auth
    )
    const { loading, error, helpDeskItems } = useSelector(
        state => state.helpDesk
    )

    if(!helpDeskItems && !loading.items && !error.items) {
        dispatch(getHelpDeskItems({session}));
    }


    const item = getHelpDeskItem(itemID, helpDeskItems);

    return (
        <HelpDeskItem item={item} />
    )

}

export default AccountLayoutHelpItem;
