import HeaderButton from "./HeaderButton";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


const AccountButton = () => {

    const navigate = useNavigate();
    const { session } = useSelector(
        state => state.auth
    )
    const loggedIn = session && session.bPartnerID;

    const handleClick = () => {
        if(loggedIn) {
            navigate("/account/")
        } else {
            navigate("/signin")
        }
    }

    const getTrimmedName = (name) => {
        if(name.length > 30) {
            return name.slice(0,27) + "...";
        } else {
            return name;
        }
    }

    const heading = loggedIn ? getTrimmedName(session.businessName) : "Account";
    const subHeading = loggedIn ? getTrimmedName(session.userName) : "Sign In"

    return (
        <>
            <HeaderButton
                heading={heading}
                subHeading={subHeading}
                onClick={handleClick}
                className="account"
            >
                <img alt="" src="/assets/Account.svg"/>
            </HeaderButton>
        </>
    )

}

export default AccountButton;