
const filterTransform = (list, filter, transform) => {
    const newList = [];
    for(const item of list) {
        if(filter(item))
            newList.push(transform(item));
    }
    return newList;
}

const ListUtil = {
    filterTransform
}

export default ListUtil;

