import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";

export const getClearanceItems = createAsyncThunk(
    'clearance/items',
    async ({session, filters}, {rejectWithValue, dispatch}) => {
        try {

            const req = request.model(api.MODEL.DISCOUNT)
                .select("M_Product_ID", "available")
                .filter(
                    condition.eq("isActive", "true"),
                    condition.greaterThan("available", "0"),
                    condition.eq("AD_Org_ID", session.orgID)
                )

            const results = await api.getAllRequestPages(req, session);
            return { items: results }
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)