import {useState} from "react";
import {range} from "../../res/dataServices/range";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {useNavigate} from "react-router-dom";
import useWebUpsell from "../../newStructure/services/state/promotion/useWebUpsell";

const TIMEOUT = 10000;

const PromoImage = ({imgSrc, onClick}) => {

    return (
        <SwitchTransition mode={"out-in"}>
            <CSSTransition classNames="promo" timeout={500} key={imgSrc}>
                <img alt="" src={imgSrc} onClick={onClick} className={onClick ? "clickable-img" : ""} />
            </CSSTransition>
        </SwitchTransition>
    )

}

const PromotionDisplay = ({items}) => {

    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemCount = items.length;

    const next = () => {
        const newIndex = (currentIndex + 1) % itemCount;
        setCurrentIndex(newIndex);
    }

    setTimeout(next, TIMEOUT);

    const getAdjustedIndex = (i) => {
        return (i + 1 + currentIndex) % itemCount;
    }

    const getImgSrc = (i) => {
        const adjustedIndex = (i + 1 + currentIndex) % itemCount;
        return items[adjustedIndex].imageUrl;
    }

    const getOnClick = (promo) => {
        if(promo.productID) {
            return () => navigate("/product/" + promo.productID)
        }
    }

    return (
        <div className="promo-grid">
            <div className="promotion-tile-large">
                <PromoImage
                    imgSrc={items[currentIndex].imageUrl}
                    onClick={getOnClick(items[currentIndex])}
                />
            </div>
            {items.length > 2 ?
                range(items.length - 1).map(i =>
                    <div className="promotion-tile-small" key={i}>
                        <PromoImage
                            key={i}
                            imgSrc={getImgSrc(i)}
                            onClick={getOnClick(items[getAdjustedIndex(i)])}
                        />
                    </div>
                ) : items.length > 1 ?
                    <div className="promotion-tile-large">
                        <p>hi</p>
                        <PromoImage
                            imgSrc={items[getAdjustedIndex(0)].ImageURL}
                            onClick={getOnClick(items[getAdjustedIndex(0)])}
                        />
                    </div> :
                    <></>
            }
        </div>
    )

}

const Promo = () => {

    const { promotions } = useWebUpsell();

    const carouselItems = promotions ? promotions : [];

    if(carouselItems && carouselItems.length > 0) {
        return (
            <PromotionDisplay items={ carouselItems }/>
        )
    }
}

export default Promo;