import useSession from "../../services/state/session/useSession";
import {env} from "../../../res/config/env";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import SignInInput from "./SignInInput";
import SignUpInput from "./SignUpInput";
import BusinessPartnerSelect from "./BusinessPartnerSelect";
import {Button} from "../../../components/general/input/Button";
import {useNavigate} from "react-router-dom";

const SignInController = () => {

    const navigate = useNavigate();
    const session = useSession();

    return (
        <GoogleReCaptchaProvider reCaptchaKey={env.CAPTCHA_KEY}>
            {(!session.isAuthenticated() && !session.isSignedIn()) &&
                <>
                    <SignInInput/>
                    <div className="sign-in-or">
                        <p>OR</p>
                    </div>
                    <SignUpInput/>
                </>
            }
            {(session.isAuthenticated() && !session.isSignedIn()) &&
                <BusinessPartnerSelect
                    session={session}
                />
            }
            {session.isSignedIn() &&
                <div>
                    <p className="sign-in-header">Welcome back {session.getUserName()}</p>
                    <Button onClick={() => navigate("/")}>Back to home</Button>
                </div>
            }
        </GoogleReCaptchaProvider>
    )


}

export default SignInController;