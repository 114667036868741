import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../res/rest/api";
import {request} from "../../res/rest/restRequest";
import {env} from "../../res/config/env";
import axios from "axios";
import {idempiereProcess} from "../../res/rest/process";


export const requestPasswordReset = createAsyncThunk(
    'password/requestReset',
    async ({email},  {rejectWithValue, dispatch}) => {
        try {

            console.log("with email", email)
            const req = request.process(api.PROCESS.REQUEST_PASSWORD_RESET)
                .param("email", email);
            const url = env.API_URL + req.buildRequest();
            console.log("body", req.buildRequestBody());
            const resp = await axios.post(url, req.buildRequestBody(), api.auth());
            const data = idempiereProcess.handleResponseThrowingException(resp);
            return data.summary;
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'password/resetPassword',
    async ({token, newPassword},  {rejectWithValue, dispatch}) => {
        try {
            const base64Pass = btoa(newPassword);

            const req = request.process(api.PROCESS.RESET_PASSWORD)
                .param("token", token)
                .param("password", base64Pass);
            const url = env.API_URL + req.buildRequest();
            console.log("url")
            const resp = await axios.post(url, req.buildRequestBody(), api.auth());
            const data = idempiereProcess.handleResponseThrowingException(resp);

            return data.summary;
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)