import useBanking from "../../../state/account/useBanking";
import CardDisplay from "../../../payment/CardDisplay";

const BankDisplay = ({ bank }) => {

    const isCard = bank.CreditCardNumber && bank.CreditCardNumber.length > 0;

    return (
        <div className="tile" style={{padding: "4pt 8pt"}}>
            {isCard ?
                <CardDisplay bank={bank} /> :
                <></>
            }
        </div>
    )

}

const AccountLayoutBilling = () => {

    const { banking } = useBanking();

    return (
        <div style={{display: "flex", flexFlow: "column", gap: "8pt"}}>
            <h1>Billing Information</h1>
            {banking && banking.map((e,i) =>
                <BankDisplay key={i} bank={e}/>
            )}
        </div>
    )

}

export default AccountLayoutBilling;
