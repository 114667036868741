import PageLayout from "../../../../components/page/PageLayout";
import PaymentMethodSwitcher from "../../payment/PaymentMethodSwitcher";
import {PaymentRules} from "../../../logic/payment/paymentRules";
import Input from "../../../../components/general/input/Input";
import {Button} from "../../../../components/general/input/Button";
import {useState} from "react";
import CreditCard from "../../../logic/payment/creditCard";
import useAddress from "../../../../components/state/account/useAddress";
import {AddressInput} from "../../../../components/checkout/form/InputDelivery";
import '../../style/page/pagePayment.css'
import PaymentProcessor, {runInitialPaymentStep} from "../../../../components/payment/PaymentProcessor";
import {useDispatch, useSelector} from "react-redux";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Popup from "../../../../components/general/popup/Popup";
import useCreditStatus from "../../../services/state/account/useCreditStatus";

const formatDetails = (session, cardInput, amount, location) => {

    return {
        ...cardInput,
        currency: 'GBP',
        amount: Number(amount).toFixed(2),
        locationID: location.id,
        bPartnerID: session.bPartnerID,
        userID: session.userID,
    }
}

const PagePayment = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(state => state.auth);
    const { creditStatus, creditStatusLoading, getCreditStatus } = useCreditStatus();
    // const { banking } = useBanking();
    const { locations } = useAddress();
    const { paymentResult, error: paymentError } = useSelector(
        state => state.payment
    );

    const [ cardInput, setCardInput ] = useState();
    const [ billingAddress, setBillingAddress ] = useState();
    const [ paymentAmount, setPaymentAmount ] = useState();
    const [ showPaymentMade, setShowPaymentMade ] = useState(false);

    // const savedCards = Banking.filterSavedCards(banking);
    const readyToMakePayment = CreditCard.validateCardInput(cardInput).ok
        && billingAddress
        && paymentAmount
        && paymentAmount > 0;

    const onPayClick = () => {
        if(readyToMakePayment) {
            const details = formatDetails(session, cardInput.cardInput, paymentAmount, billingAddress);
            runInitialPaymentStep(dispatch, session, details);
        }
    }

    const onPaymentMade = () => {
        if(paymentResult?.success) {
            setShowPaymentMade(true);
            getCreditStatus();
        }
    }

    useValueChangeListener(onPaymentMade, [ paymentResult?.success ]);

    return (
        <PageLayout requireSignIn>
            <div className="page-content page-min-height page-content-buffer">
                <div className="page-readable-width page-payment-content">
                    <p className="page-h1">Make Payment</p>
                    <p className="page-h5">Credit Limit</p>
                    <p className={creditStatusLoading ? "loading" : "page-h4"}>£{creditStatus?.SO_CreditLimit}</p>
                    <p className="page-h5">Open Balance</p>
                    <p className={creditStatusLoading ? "loading" : "page-h4"}>£{creditStatus?.TotalOpenBalance}</p>
                    <div className="page-payment-input-container">
                        <p className="page-h4">Payment Amount £</p>
                        <Input
                            hint="Please enter payment amount."
                            onBlur={e => setPaymentAmount(e.target.value)} />
                        <p className="page-h4">Billing Address</p>
                        {locations &&
                            <AddressInput
                                selectedAddress={billingAddress}
                                locations={locations}
                                onSelect={setBillingAddress}
                                setCheckoutBlock={() => {}}
                            />
                        }
                        <p className="page-h4">Card Input</p>
                        <PaymentMethodSwitcher
                            paymentRules={[PaymentRules.K]}
                            selectedRule={PaymentRules.K}
                            onCardInput={setCardInput}
                            savedCards={[]}
                        />
                        {(paymentError || paymentResult?.declined) &&
                            <p className="warning">Payment has been declined. Please check card details.</p>
                        }
                        <Button
                            onClick={onPayClick}
                            className={readyToMakePayment ? "payment-button" : "payment-button inactive" }>
                            Make Payment
                        </Button>
                    </div>
                    <PaymentProcessor session={session} />
                </div>
            </div>
            {showPaymentMade &&
                <Popup
                    onOverlayClick={() => setShowPaymentMade(false)}
                >
                    <div className="page-payment-popup-container">
                        <p className="page-h2">Payment Made Successfully</p>
                        <p className="page-payment-notify">Your payment has been received. Thank You.</p>
                        <Button onClick={() => setShowPaymentMade(false)}>Back</Button>
                    </div>
                </Popup>
            }
        </PageLayout>
    )
}

export default PagePayment;