import useWebProduct from "../../state/useWebProduct";
import ProductTileSkeleton from "../ProductTileSkeleton";
import "./checkoutTile.css"

const Content = ({heading, text, headingClass, width}) => {

    const style = width ? {width} : null;

    return (
        <div className="product-checkout-content" style={style}>
            <p className={`product-checkout-heading ${headingClass ? headingClass : ""}`}>
                {heading}
            </p>
            <p className="product-checkout-text">{text}</p>
        </div>
    )
}

const ProductCheckoutTile = ({ cartLine }) => {
    const { webProduct } = useWebProduct({orderLineID: cartLine.id, product: cartLine.M_Product_ID});

    if(!webProduct.isProductSet()) {
        return (
            <ProductTileSkeleton compact={true} />
        )
    }


    return (
        <div className="product-checkout-item">
            <img src={webProduct.getImageUrl()} alt=""/>
            <Content
                heading={webProduct.getValue()}
                text={webProduct.getName()}
                headingClass="product-checkout-value"
                width="200pt"
            />
            <div style={{width: "0", marginLeft: "auto"}} />
            <Content
                heading="Quantity"
                width="66pt"
                text={webProduct.getQuantityEntered() + " " + webProduct.getSelectedUom().name}
            />
            <Content
                heading="Line Price"
                width="60pt"
                text={webProduct.getCalculatedLinePrice().toFixed(2)}
            />
            <Content
                heading="Inc. VAT"
                text={webProduct.getTaxedCalculatedLinePrice()}
            />
        </div>
    )

}

export default ProductCheckoutTile;