import {useSelector} from "react-redux";
import promotionRequest from "../../rest/request/product/promotionRequest";
import useAuthRequest from "../request/useAuthRequest";

const usePromotions = () => {

    const { session } = useSelector(
        state => state.auth
    );

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const isLoggedIn = session?.bPartnerID > 0;

    const getPromotionProducts = () => {
        const req = promotionRequest(session.bPartnerID);
        sendIt(req.sendRequest)
    }

    if(isLoggedIn && !loading && !error && !result) {
        getPromotionProducts();
    }

    return {
        loading,
        error,
        promotions : result?.items
    }

}

export default usePromotions;