import {createSlice} from '@reduxjs/toolkit';
import {login, refreshLogin} from "../action/authActions";

const initialState = {
    loading: false,
    error: null,
    refreshing: false,
    refreshError: null,

    session: null,
    sessionTimedOut: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        sessionTimeOut: ( state ) => {
            state.sessionTimedOut = true;
            state.session = null;
        },
        setBusinessPartner: ( state, { payload }) => {
            const updatedSession = {...state.session};
            updatedSession.bPartnerID = payload.id;
            updatedSession.businessName = payload.Name;
            updatedSession.businessCode = payload.Value;
            updatedSession.canBackOrder = payload["X_Allow_Back_Order"];
            updatedSession.priceListID = payload["M_PriceList_ID"]?.id
            state.session = updatedSession;
        },
        logOut: ( state, a ) => {
            state.session = null;
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [login.fulfilled]: (state, { payload }) => {
            state.loading = false

            if(payload.orgs) {
                state.orgSelect = payload;
            } else {

                state.orgSelect = null;
                state.session = payload;
            }
        },
        [login.rejected]: (state, { payload }) => {
            console.log("slice error received")
            state.loading = false
            state.error = payload
        },
        [refreshLogin.pending]: (state) => {
            state.refreshing = true
            state.refreshError = null
        },
        [refreshLogin.fulfilled]: (state, { payload }) => {
            state.refreshing = false
            state.session = {
                ...state.session,
                authToken: payload.token,
                refreshToken: payload.refresh_token
            }
        },
        [refreshLogin.rejected]: (state, { payload }) => {
            console.log("slice error received", payload);
            state.refreshing = false
            state.refreshError = payload
        },
    }
})

export const { sessionTimeOut, setBusinessPartner, logOut } = authSlice.actions;
export default authSlice.reducer;