
const Timer = () => {

    const timer = {};

    timer.start = () => {
        timer.startTime = Date.now();
    }

    timer.stop = () => {
        timer.endTime = Date.now();
        timer.logMillis();
    }

    timer.logMillis = () => {
        console.log("Time", timer.endTime - timer.startTime, "ms");
    }

    return timer;

};

export default Timer;