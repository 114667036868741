import React, {useEffect} from 'react';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import PageHome from "./components/page/PageHome";
import PageCheckOut from "./components/page/PageCheckOut";
import Page404 from "./components/page/Page404";
import PageCategoryOverview from "./components/page/PageCategoryOverview";
import PageOrderEntry from "./newStructure/component/page/product/PageOrderEntry";
import PageAboutUs from "./components/about/PageAboutUs";
import PagePrivacyStatement from "./components/about/PagePrivacyStatement";
import PageTermsAndConditions from "./components/about/PageTermsAndConditions";
import PageClearance from "./newStructure/component/page/product/PageClearance";
import PageAccount from "./components/page/PageAccount";
import PagePromotion from "./newStructure/component/page/product/PagePromotion";
import PageSearch from "./newStructure/component/page/product/PageSearch";
import PageResetPassword from "./components/page/PageResetPassword";
import {ErrorBoundary} from "./components/general/error/ErrorBoundary";
import GeneralError from "./components/general/error/GeneralError";
import PageTest from "./components/page/PageTest";
import PageProductCategory from "./newStructure/component/page/product/PageProductCategory";
import PageProduct from "./newStructure/component/page/product/PageProduct";
import PageGeneralEnquiry from "./newStructure/component/page/customerService/PageGeneralEnquiry";
import PageWebFeedback from "./newStructure/component/page/customerService/PageWebFeedback";
import PagePurchaseOrderConfirmation from "./newStructure/component/page/vendor/PagePurchaseOrderConfirmation";
import PagePayment from "./newStructure/component/page/account/PagePayment";
import PageSignIn from "./newStructure/component/page/account/PageSignIn";

const PageError = () => {
    return (
        <div className="body">
            <GeneralError />
        </div>
    )
}

function App() {

    const trackPageChange = () => {
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    useEffect(() => {
        trackPageChange();
    }, []);

    return (
        <BrowserRouter>
            <div className="App">
                <ErrorBoundary fallBack={<PageError/>}>
                    <Routes>
                        <Route exact path="/" element={<PageHome />}/>

                        {/* Used to redirect customers with old link */}
                        <Route exact path='/wstore' element={< PageHome />}/>
                        <Route exact path='/wstore/*' element={< PageHome />}/>

                        <Route exact path='/signin' element={< PageSignIn />}/>

                        <Route path='/category/:categoryID' element={< PageProductCategory />}/>
                        <Route path='/category/:categoryID/:pageNo' element={< PageProductCategory />}/>
                        <Route path='/category/:categoryID/:pageNo/:filter' element={< PageProductCategory />}/>
                        <Route path='/category' element={< PageCategoryOverview />}/>
                        <Route path='/product/:productID' element={< PageProduct />}/>
                        <Route path='/clearance' element={< PageClearance />}/>
                        <Route path='/clearance/:pageNo' element={< PageClearance />}/>
                        <Route path='/promotion' element={< PagePromotion />}/>
                        <Route path='/promotion/:pageNo' element={< PagePromotion />}/>
                        <Route path='/search/:searchTerm' element={< PageSearch />}/>
                        <Route path='/search/:searchTerm/:pageNo' element={< PageSearch />}/>

                        <Route exact path='/checkout' element={<Navigate to={"/checkout/cart"}/>}/>
                        <Route path='/checkout/:checkOutStage' element={< PageCheckOut />}/>

                        <Route exact path='/account/payment' element={< PagePayment />}/>
                        <Route exact path='/account' element={<Navigate to={"/account/overview"}/>}/>
                        <Route exact path='/account/:accountViewParent/:accountView/:id' element={< PageAccount />}/>
                        <Route path='/account/:accountViewParent/:accountView' element={< PageAccount />}/>
                        <Route path='/account/:accountViewParent' element={< PageAccount />}/>

                        <Route path='/vendor/poConfirm/:token' element={< PagePurchaseOrderConfirmation />}/>

                        <Route exact path="/test" element={< PageTest />}/>

                        <Route exact path="/orderEntry" element={< PageOrderEntry />}/>

                        <Route exact path='/reset/:token' element={< PageResetPassword />}/>

                        <Route exact path="/about/aboutUs" element={<PageAboutUs />}/>
                        <Route exact path="/about/privacy" element={<PagePrivacyStatement />}/>
                        <Route exact path="/about/termsAndConditions" element={<PageTermsAndConditions />}/>

                        <Route exact path="/contact/generalEnquiry" element={<PageGeneralEnquiry/>} />
                        <Route exact path="/contact/webFeedback" element={<PageWebFeedback/>} />

                        <Route path='/*' element={<Page404 />}></Route>
                    </Routes>
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    );
}

export default App;
