import useColleague from "../../../state/account/useColleague";
import ColleagueInfo from "../user/ColleagueInfo";

const AccountLayoutColleagues = () => {

    const { colleagues } = useColleague();

    return (
        <>
            <h1>Colleagues</h1>
            <div className="account-content-container">
                {colleagues && colleagues.map((e,i) =>
                    <ColleagueInfo colleague={e} />
                )}
            </div>
        </>
    )
}

export default AccountLayoutColleagues;
