
const productSearchParamsBuilder = () => {

    const builder = {};
    const params = {};
    params.filters = {};

    builder.categoryID = (categoryID) => {
        params.categoryID = categoryID;
        return builder;
    }

    builder.pageSize = (pageSize) => {
        params.pageSize = pageSize;
        return builder;
    }

    builder.pageNo = (pageNo) => {
        params.pageNo = pageNo;
        return builder;
    }

    builder.filterAttribute = (attributeID, value) => {
        if(!params.filters[attributeID]) params.filters[attributeID] = [];
        params.filters[attributeID].push(value);
        return builder;
    }

    builder.productIDs = (productIDs) => {
        params.productIDs = productIDs;
        return builder;
    }

    builder.excludedPriceList = (priceListID) => {
        params.excludedPriceListID = priceListID;
        return builder;
    }

    params.getCategoryID = () => params.categoryID;
    params.getPageSize = () => params.pageSize;
    params.getPageNo = () => params.pageNo;
    params.getStartPosition = () => {
        if(params.pageNo > 1) {
            return ((params.pageNo - 1) * params.pageSize);
        } else {
            return 0;
        }
    }
    params.getFilterAttributes = () => params.filters;
    params.getProductIDs = () => params.productIDs;
    params.getExclusionPriceList = () => params.excludedPriceListID;

    builder.getParams = () => params;

    return builder;
}

export default productSearchParamsBuilder;