import {api} from "../../../../../res/rest/api";
import axios from "axios";
import {condition, request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";

const businessPartnerRequest = (bPartnerID) => {

    const builder = {};
    const req = request.model(api.MODEL.C_BPARTNER)
        .select("paymentRule")
        .filter(condition.eq("c_bpartner_ID", bPartnerID))
        .hostURL(env.API_URL);

    builder.sendIt = async (session) => {
        const resp = await axios.get(req.buildRequest(), api.auth(session));
        const bp = resp.data.records[0];
        if(!bp) {
            throw new Error("No BP found for id: " + bPartnerID);
        } else {
            return bp;
        }
    }

    return builder;
}

export default businessPartnerRequest;