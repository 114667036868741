import {extendBuilder} from "../setup/reduxRequestHandler";
import {getClearanceItems} from "../action/clearanceActions";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: {},
    error: {},

    clearance: null
}


const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(getClearanceItems, "clearance", (state, { payload }) => {
            state.clearance = payload.items;
        })

}

export const clearanceSlice = createSlice({
    name: 'clearance',
    initialState,
    extraReducers: buildExtraReducers
})


export default clearanceSlice.reducer;
