import {Button} from "../../../components/general/input/Button";
import PurchaseOrderLine from "./PurchaseOrderLine";
import PurchaseOrderLineGrid from "./PurchaseOrderLineGrid";
import SkeletonDiv from "../../../components/general/loading/SkeletonDiv";

const PurchaseOrderError = ({ error }) => {

    return (
        <div className="purchase-order-content">
            <p className="purchase-order-sub-value">
                Somethings gone wrong! We could not find the purchase order. If issues persist,
                please contact our sales team.
            </p>
        </div>
    )

}

const PurchaseOrderLoading = () => {

    return (
        <div className="purchase-order-content">
            <SkeletonDiv style={{height: "32px", width: "280px"}} />
            <SkeletonDiv style={{height: "26px", width: "320px", marginTop: "22px"}} />
            <SkeletonDiv style={{height: "26px", width: "200px", marginTop: "12px"}} />
        </div>
    )

}

const PurchaseOrderDisplay = ({ poManager }) => {
    const { po, updating, acknowledge } = poManager;

    const onAcknowledgeClick = () => {
        if(!updating) {
            acknowledge()
        }
    }

    return (
        <div className="purchase-order-content">
            <p className="purchase-order-no">Order {po.getDocumentNo()}</p>
            <p className="purchase-order-sub-heading">Date Ordered</p>
            <p className="purchase-order-sub-value">{po.getDateOrdered()}</p>
            <p className="purchase-order-sub-heading">Delivery Date <span>( estimate )</span></p>
            <p className="purchase-order-sub-value">{po.getDatePromised()}</p>
            <p className="purchase-order-sub-heading">Grand Total</p>
            <p className="purchase-order-sub-value">£{po.getGrandTotal()}</p>
            <p className="purchase-order-line-heading">Lines</p>
            <PurchaseOrderLineGrid >
                {po.getLines()?.map(line => <PurchaseOrderLine line={line} />)}
            </PurchaseOrderLineGrid>
            {!po.isConfirmed() ?
                <Button
                    onClick={onAcknowledgeClick}
                    className={updating ? "button-loading" : ""}>
                    Acknowledge Order
                </Button> :
                <p className="purchase-order-confirmation">Order Has Been Confirmed</p>
            }
            <div style={{height: "60px"}} />
        </div>
    )

}

const PurchaseOrder = ({ poManager }) => {

    if(poManager.loading) {
        return <PurchaseOrderLoading />
    } else if(poManager.error) {
        return <PurchaseOrderError error={poManager.error} />
    } else {
        return <PurchaseOrderDisplay poManager={poManager} />
    }

}

export default PurchaseOrder;