import {env} from "../../../res/config/env";
import {useNavigate} from "react-router-dom";

const Logo = () => {

    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    }

    return (
        <img src={env.CLIENT_LOGO + "logoWithText.png"} alt="logo" onClick={goToHome}/>
    )
}

export default Logo;