import PageLayout from "./PageLayout";
import {Button} from "../general/input/Button";
import useSolrProduct from "../../newStructure/services/state/product/useSolrProduct";
import productSearchParamsBuilder from "../../newStructure/logic/product/productSearchParams";

const PageTest = () => {

    const {
        getProducts
    } = useSolrProduct();

    const runTest = async () => {
        const params = productSearchParamsBuilder()
            .categoryID(110828)
            .pageSize(20)
            .pageNo(1)
            .getParams();
        getProducts(params)
    }

    return (
        <PageLayout>
            <div>
                <Button
                    style={{
                        height: "300px",
                        width: "70vw",
                        margin: "100px auto",
                        textAlign: "center",
                        display: "flex"
                    }}
                    onClick={runTest}>
                    <p style={{
                        margin: "auto auto",
                        fontSize: "10em"
                    }}>Test</p>
                </Button>
            </div>
        </PageLayout>
    )

}

export default PageTest;