import useAuthRequest from "../request/useAuthRequest";
import invoiceRequest from "../../rest/request/account/invoiceRequest";

const useInvoices = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getInvoices = ({ ids, startDate, endDate, pageNo = 1, pageSize = 10, includeLines }) => {
        const req = invoiceRequest()
            .top(pageSize)
            .skip(pageSize * (pageNo - 1));

        if(startDate || endDate)
            req.forDateRange(startDate, endDate);

        if(includeLines) {
            req.includeLines();
            req.includeProducts();
        }

        if(ids)
            req.forIDs(...ids);



        sendIt(req.sendIt)
    }

    return {
        loading,
        error,
        invoices: result?.records,
        count: result?.count,
        getInvoices
    }
}

export default useInvoices;