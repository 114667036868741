import useRequestTracker from "../request/useRequestTracker";
import solrFiltersRequest from "../../rest/request/product/solrFiltersRequest";
import filterMap from "../../../logic/filter/filterMap";

const useSolrFilters = () => {

    const {
        result,
        sendItWithTask
    } = useRequestTracker();

    const onFiltersReceived = (result) => {

    }

    const getFilters = ({ categoryID }) => {
        const request = solrFiltersRequest()
            .setCategoryID(categoryID);

        sendItWithTask(request.sendRequest, onFiltersReceived);
    }

    // TODO dont user result in final thing
    const filters = filterMap(result?.attributes, result?.values);

    return {
        getFilters,
        filters
    }
}

export default useSolrFilters;