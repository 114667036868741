import clearanceRequest from "../../rest/request/product/clearanceRequest";
import {useSelector} from "react-redux";
import useAuthRequest from "../request/useAuthRequest";

const useClearance = () => {

    const { session } = useSelector(
        state => state.auth
    );

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const isLoggedIn = session?.bPartnerID > 0;

    const getClearanceProducts = () => {
        const req = clearanceRequest(session.orgID);
        sendIt(req.sendRequest)
    }

    if(isLoggedIn && !loading && !error && !result) {
        getClearanceProducts();
    }

    return {
        loading,
        error,
        clearanceIDs : result?.items
    }
}

export default useClearance;
