import {env} from "../../../../../res/config/env";
import axios from "axios";
import SolrField from "../../../../logic/search/solrField";

const buildValueRequestUrl = () => {

    const url = env.API_SOLR_URL;
    const select = "select?q="

    const paramList = "(Table_Name:x_upsell_product)";

    const pageSize = "&rows=" + 100

    return url + select + paramList + pageSize;
}

const formatUpsell = (rawData) => {
    const upsell = {};
    upsell.productID    = SolrField.getValue(rawData, "m_product_ID");
    upsell.seqNo        = SolrField.getValue(rawData, "seqNo");
    upsell.startDate    = SolrField.getValue(rawData, "startDate");
    upsell.endDate      = SolrField.getValue(rawData, "endDate");
    upsell.campaignID   = SolrField.getValue(rawData, "c_campaign_ID");
    upsell.imageUrl     = SolrField.getValue(rawData, "imageUrl");
    upsell.description  = SolrField.getValue(rawData, "description");

    return upsell;
}

const solrUpsellProductRequest = () => {

    const builder = {};

    builder.sendRequest = async () => {
        const response = await axios.get(buildValueRequestUrl());
        const data = response.data.response;
        return data.docs.map(formatUpsell);
    }

    return builder;
}

export default solrUpsellProductRequest;
