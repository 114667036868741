import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";
import {usualsRanking} from "../../res/dataServices/usualsRanking";
import {getProducts} from "./productActions";

const getUsuals = async (session) => {
    const reqBuilder = request.model(api.MODEL.USUALS)
        .filter(condition.eq("c_bpartner_ID", session.bPartnerID))
        .filter(condition.eq("ad_user_ID", session.userID))
    const usuals = await api.getAllRequestPages(reqBuilder, session)
    return usualsRanking.rankByCategory(usuals);
}

export const getUsualProductsForCategory = createAsyncThunk(
    'usuals/product',
    async ({session, categoryID, productIDList}, {rejectWithValue, dispatch}) => {
        try {

            dispatch(getProducts({
                session,
                filter: { productIDs: productIDList },
                key: "usuals",
            }))

        } catch (error) {
            console.log(error)
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getUsualsOverview = createAsyncThunk(
    'usuals/overview',
    async ({session}, {rejectWithValue, dispatch}) => {
        try {
            console.log("getting usuals")
            const usuals = await getUsuals(session);
            console.log("usuals res", usuals);
            for(const category of usuals.categories) {
                const categoryID = category.id;
                const productIDList = usuals[categoryID].slice(0,6)
                    .map(e => e["M_Product_ID"].id);
                dispatch(getUsualProductsForCategory({session, categoryID, productIDList}));
            }

            return {usuals};

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)