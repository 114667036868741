import {useSelector} from "react-redux";
import useAuthRequest from "../request/useAuthRequest";
import businessPartnerRequest from "../../rest/request/account/businessPartnerRequest";
import getBusinessPartner from "../../../logic/account/businessPartner";

const useBusinessPartner = () => {

    const { session } = useSelector(state => state.auth);
    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const sendBPRequest = () => {
        const req = businessPartnerRequest(session.bPartnerID);
        sendIt(req.sendIt);
    }

    if(!loading && !error && !result && session.bPartnerID > 0) {
        sendBPRequest()
    }

    const businessPartner = result ? getBusinessPartner(result) : null;

    return {
        loading,
        error,
        businessPartner
    }
}

export default useBusinessPartner;