import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../../redux/action/authActions";
import {env} from "../../../../res/config/env";

const getErrorDisplayText = (loginMethod, error) => {
    if(!error) return;

    const lowerError = error.toLowerCase();
    if(lowerError === "request failed with status code 401"){
        return `Invalid ${loginMethod} or Password. Please double-check your credentials and try again.`;
    } else if(lowerError === "forbidden") {
        return "Failed captcha check. Please wait a moment and try again.";
    } else {
        return "Error from server: " + error;
    }
}

const useSignIn = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [ loadingCaptcha, setLoadingCaptcha ] = useState(false);

    const { error } = useSelector(
        (state) => state.auth
    )

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return {blocked: true};
        }
        setLoadingCaptcha(true);
        const token = await executeRecaptcha('login');
        setLoadingCaptcha(false);
        return {captchaToken: token};
    }, [executeRecaptcha]);

    const { loading : authLoading } = useSelector(
        (state) => state.auth
    )

    const loading = authLoading || loadingCaptcha;

    const doLogIn = async (captchaToken) => {
        // Bad fix for issue:
        // When importing as normal, get "can't access lexical declaration 'login' before initialization"
        // const { login } = await import("../../../../redux/action/authActions");
        dispatch(login({userName, password, captchaToken}))
    }

    const dispatch = useDispatch();
    const tryLogin = async () => {
        if(loading) return;

        const captchaRes = await handleReCaptchaVerify();
        if(captchaRes.blocked) {
            // TODO error report to user
            await doLogIn(null);
        } else {
            const { captchaToken } = captchaRes;
            await doLogIn(captchaToken);
        }
    }

    const errorMsg = getErrorDisplayText(env.USER_NAME_TYPE, error)

    return {
        setUserName,
        setPassword,
        tryLogin,
        loading: loading || loadingCaptcha,
        errorMsg
    }

}

export default useSignIn;