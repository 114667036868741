import PageLayout from "../../../../components/page/PageLayout";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import Pagination from "../../navigation/Pagination";
import ProductFilter from "../../product/ProductFilter";
import ProductLayout from "../../product/ProductLayout";
import useClearance from "../../../services/state/product/useClearance";
import ProductList from "../../product/ProductList";
import useProductIDPage from "../../../services/state/product/useProductIDPage";
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";
import {ProductPurchaseInfo} from "../../../../res/product/productPurchaseInfo";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Clearance", nav: "/clearance", selected:true} ]

const PageClearance = () => {

    const navigate = useNavigate();

    const { pageNo : pageNoParam } = useParams();

    const pageNo = pageNoParam && !isNaN(Number(pageNoParam)) ? Number(pageNoParam) : 1;
    const [ currentPageNo, setCurrentPageNo ] = useState(pageNo);
    useEffect(() => {
        if(currentPageNo !== pageNo) {
            setCurrentPageNo(pageNo)
            window.scrollTo(0, 0)
        }
    }, [currentPageNo, pageNo])

    const {
        loading : clearanceLoading,
        clearanceIDs,
    } = useClearance();

    const productIDs = useMemo(
        () => clearanceIDs?.map(id => id.M_Product_ID.id),
        [clearanceIDs]
    );

    const {
        loading: productsLoading,
        products,
        pageCount,
    } = useProductIDPage(pageNo, productIDs);

    const onPageChange = (pageNo) => {
        navigate("/clearance/" + pageNo);
    }

    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo], () => `/clearance/${pageNo}`);

    return (
        <PageLayout
            requireSignIn={true}
        >
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <div className="product-layout-filters">
                        <ProductFilter
                            filters={{}}
                        />
                    </div>
                    <ProductList
                        products={products}
                        loading={productsLoading || clearanceLoading}
                        getDisplayProduct={ProductPurchaseInfo.getClearanceDisplayProduct}
                    />
                </ProductLayout>
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount - 1}
                    onPageRequested={onPageChange}
                    loading={productsLoading || clearanceLoading}
                />
            </div>
        </PageLayout>
    )
}

export default PageClearance;
