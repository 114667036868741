import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";

export const getPromotionItems = createAsyncThunk(
    'promotion/items',
    async ({session, filter}, {rejectWithValue, dispatch}) => {
        try {

            const req = request.model(api.MODEL.PRICE_PROMOTION)
                .select("c_campaign_ID", "Name", "M_Product_ID", "startDate", "endDate")
                .filter(condition.eq("c_bpartner_ID", session.bPartnerID));

            const results = await api.getAllRequestPages(req, session);
            return { items: results }

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)