import {createSlice} from "@reduxjs/toolkit";
import {
    cancelBackOrder,
    createLocation,
    getActiveBackOrders,
    getBanking,
    getBPLocations,
    getColleagues,
    getCreditStatus,
    getOrderLines,
    updateLocation
} from "../action/accountActions";
import {extendBuilder} from "../setup/reduxRequestHandler";

const initialState = {
    loading: {},
    error: {},

    ordering: {},
    activeShipments: null,
}

const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(getBPLocations, "location", (state, {payload}) => {
            state.locations = payload.locations;
        })
        .addStandardCase(createLocation, "createLocation", (state, {payload}) => {
            if(state.locations) state.locations.push(payload.bpLocation);
            else state.locations = [ payload.bpLocation ];
        })
        .addStandardCase(getOrderLines, "orderLines", (state, {payload}) => {
            state.ordering.orderLines = {[payload.orderID]: payload.orderLines};
        })
        .addStandardCase(getColleagues, "colleague", (state, {payload}) => {
            state.colleagues = payload.colleagues;
        })
        .addStandardCase(getBanking, "banking", (state, {payload}) => {
            state.banking = payload.banking;
        })
        .addStandardCase(getCreditStatus, "creditStatus", (state, {payload}) => {
            state.creditStatus = payload.creditStatus;
        })
        .addStandardCase(getActiveBackOrders, "backOrders", (state, {payload}) => {
            state.backOrderLines = payload.backOrderLines;
        })
        .addStandardCase(cancelBackOrder, "cancelBackOrder", (state, {payload}) => {
            if(payload.response === "@Closed") {
                const olID = payload.orderLineID;
                const boLines = state.backOrderLines;
                const boIndex = boLines.findIndex(ol => ol["C_OrderLine_ID"].id === olID);
                state.backOrderLines = [...boLines.slice(0, boIndex), ...boLines.slice(boIndex + 1)];
            } else {
                console.log("Failed to cancel back order")
            }
        })
        .addStandardCase(updateLocation, "updateLocation", (state, {payload}) => {
            const locId = payload.updatedLoc.id;
            const updatedLocs = state.locations.slice(0);
            const addressIndex = updatedLocs.findIndex(loc => loc.id === locId);
            updatedLocs[addressIndex] = {
                ...payload.updatedLoc,
                C_Location_ID: updatedLocs[addressIndex]["C_Location_ID"],
            };
            state.locations = updatedLocs;
        })
}


export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetOrderingInfo: (state) => {
            state.ordering = {};
            state.error = {
                ...state.error,
                order: false,
                orderLines: false,
                invoices: false,
                shipments: false,
            }
        },
        resetActiveShipments: (state) => {
            state.activeShipments = null;
            state.error.activeShipments = null;
        }
    },
    extraReducers: buildExtraReducers
})

export const { resetOrderingInfo, resetActiveShipments } = accountSlice.actions;
export default accountSlice.reducer;