import {createAsyncThunk} from "@reduxjs/toolkit";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";
import {env} from "../../res/config/env";
import axios from "axios";

export const createHelpDeskRequest = createAsyncThunk(
    'helpDesk/request',
    async ({session, userRequest, trackingID},  {rejectWithValue, dispatch}) => {
        try {

            console.log(userRequest)
            const req = request.model(api.MODEL.REQUEST)
                .buildRequest(env.API_URL);

            await axios.post(req, userRequest,api.auth(session));
            return {trackingID};
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getHelpDeskItems = createAsyncThunk(
    'helpDesk/items',
    async ({session}, {rejectWithValue, dispatch}) =>
    {
        try {

            const req = request.model(api.MODEL.HELP_DESK_CATEGORY)
                .select("Name", "seqNo")
                .expand(request.subQuery(api.MODEL.HELP_DESK_ITEM)
                    .select("name")
                    .filter(condition.eq("isActive", "true"))
                    .expand("R_RequestType_ID")
                )
                .buildRequest(env.API_URL);

            const response = await axios.get(req, api.auth(session));
            return response.data.records;
            
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

