import React from "react";
import PageLayout from "./PageLayout";
import "./../home/home.css"
import Promo from "../home/Promo";
import FeaturePromoQuickEntry from "../home/FeaturePromoQuickEntry";
import FeaturePromoAccount from "../home/FeaturePromoAccount";
import DeliveryCountDown from "../checkout/DeliveryCountDown";
import SignInBannerWithState from "../../newStructure/component/auth/SignInBannerWithState";

const PageHome = () => {

    return (
        <PageLayout>
            <SignInBannerWithState />
            <div className="home-body">
                <Promo />
                <FeaturePromoQuickEntry />
                <FeaturePromoAccount />
            </div>
            <DeliveryCountDown />
        </PageLayout>
    )
}

export default PageHome;