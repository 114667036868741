import {combineReducers, configureStore } from '@reduxjs/toolkit'
import {persistReducer, persistStore} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import authReducer from './slice/authSlice'
import cartReducer from './slice/cartSlice'
import categoryReducer from './slice/categorySlice'
import accountReducer from './slice/accountSlice'
import paymentReducer from './slice/paymentSlice'
import usualsReducer from './slice/usualsSlice'
import helpDeskReducer from './slice/helpDeskSlice'
import clearanceReducer from './slice/clearanceSlice'
import promotionReducer from './slice/promotionSlice'
import errorReportReducer from './slice/errorReportSlice'
import passwordReducer from './slice/passwordSlice'
import {errorReset, sessionTimeOutReset} from "./setup/cleanup";

const persistConfig = {
    key: 'root',
    storage: storageSession,
    transforms: [errorReset, sessionTimeOutReset],
    whitelist: ['auth', 'cart', 'category', 'product']
};


const rootReducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    category: categoryReducer,
    account: accountReducer,
    payment: paymentReducer,
    usuals: usualsReducer,
    helpDesk: helpDeskReducer,
    clearance: clearanceReducer,
    promotion: promotionReducer,
    errorReport: errorReportReducer,
    password: passwordReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
});


export const persistor = persistStore(store);
