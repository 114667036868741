import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {env} from "../../res/config/env";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";
import {requestFilter} from "../../res/filter/requestFilter";

const getIDConditions = (filter) => {
    const productCondition = filter.productIDs && filter.productIDs.length > 0 ?
        condition.in("m_product_ID", ...filter.productIDs)
        : null;
    const categoryCondition = filter.includeCategory && filter.includeCategory.length > 0 ?
        condition.encapsulate(
        filter.includeCategory.map(catID =>
            condition.or(
                condition.eq("M_Product_Category_ID", catID),
                condition.eq("M_Product_Category_Parent_ID", catID)
            )).join(" OR "))
        : null;

    if(productCondition && categoryCondition) {
        return condition.encapsulate(condition.or(productCondition, categoryCondition));
    } else if(productCondition) {
        return productCondition;
    } else if(categoryCondition) {
        return categoryCondition;
    }
}

const applyFilter = (req, filter) => {
    const idCondition = getIDConditions(filter);
    if(idCondition) req.filter(idCondition)

    if(filter.selection) {
        requestFilter.applyToRequest(req, filter.selection);
    }

    if(filter.page && filter.pageSize) {
        const top = filter.pageSize;
        const skip = filter.page > 0 ? (filter.page - 1) * filter.pageSize : 0;
        req.top(top).skip(skip);
    }

    req.orderBy("rank")
}

const getBPProductRequest = (session, filter) => {
    const isThisGuy = condition.eq("c_bpartner_ID", session.bPartnerID);
    const req = request.model("web_product_v")
        .filter(isThisGuy)
        .filter(condition.eq("AD_Org_ID", session.orgID))
        .filter(condition.eq("iswebstorefeatured", "true"))
        .filter(condition.eq("isSold", "true"))
        .filter(condition.eq("ad_user_ID", session.userID))
        .expand(request.subQueryList("web_product_discount_v")
            .filter(condition.eq("isActive", "true"))
            .filter(condition.eq("AD_Org_ID", session.orgID))
        )
        .expand(request.subQueryList("price_bpartner_break_current_v").filter(isThisGuy))
        .expand(request.subQueryList("m_product_uom_v"));

    applyFilter(req, filter);

    return req.buildRequest(env.API_URL);
}

const getBrochureProductRequest = (filter) => {
    const req = request.model("web_product_brochure_v")
        .expand(request.subQueryList("m_product_uom_v"));

    applyFilter(req, filter);
    return req.buildRequest(env.API_URL);
}

const getProductRequest = (session, filter) => {

    if(session && session.bPartnerID) {
        return getBPProductRequest(session, filter);
    } else {
        return getBrochureProductRequest(filter);
    }

}

const isItTheLastPage = (response, filter) => {
    if(filter.page) {
        return filter.page >= response.data["page-count"];
    } else {
        return false;
    }
}

const getFilters = async (session, attributeSetIDs, categoryID) => {
    const req = request.model("web_product_filter_v");

    if(attributeSetIDs && attributeSetIDs.length > 0) {
        req.filter(condition.in("m_attributeSet_ID", attributeSetIDs))
    }

    if(categoryID && categoryID > 0) {
        req.filter(condition.or(
            condition.eq("m_product_category_ID", categoryID),
            condition.eq("m_product_category_parent_ID", categoryID)
        ))
    }

    return api.getAllRequestPages(req, session);
}

const getRequiredFilterIDs = (passedAttributeIDs, state) => {
    if(!passedAttributeIDs) return;
    const toFetch = [...passedAttributeIDs]
    if(state.filters) {
        const receivedSetIDs = new Set();
        state.filters.forEach(f => receivedSetIDs.add(f["M_AttributeSet_ID"].id))
        return toFetch.filter(f => !receivedSetIDs.has(f))
    }
    return toFetch;
}

const mapBrandingByProduct = (productIDs, branding) => {
    const productToBranding = {};
    for(const brandItem of branding) {
        const id = brandItem.id;
        if(!(id in productToBranding)) {
            productToBranding[id] = [];
        }
        const items = productToBranding[id];
        items.push(brandItem);
    }

    for(const pid of productIDs) {
        if(!(pid in productToBranding)) {
            productToBranding[pid] = null;
        }
    }

    return productToBranding;
}

const filterProducts = (session, response) => {
    if(session && session.bPartnerID) {
        return response.data.records.filter(
            p => p.PriceList != null
        );
    } else {
        return response.data.records;
    }
}

export const getProducts = createAsyncThunk(
    'purchasing/getProducts',
    async ({ session, filter, key }, {rejectWithValue, dispatch}) => {
        try {
            const req = getProductRequest(session, filter);
            const response = await axios.get(req, api.auth(session));

            const isLastPage = isItTheLastPage(response, filter);

            // Filter products without price
            // for some reason this is a good bit faster than by doing it in the query
            const products = filterProducts(session, response)

            const categoryID = filter.includeCategory && filter.includeCategory.length > 0 ?
                filter.includeCategory[0] : 0;
            if(categoryID) {
                dispatch(getProductFilters({session, categoryID}));
            }

            const page = isNaN(filter.page) ? 1 : filter.page;

            return {
                products,
                page,
                key,
                requestedIDs: filter.productIDs,
                isLastPage
            };
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)

export const getProductFilters = createAsyncThunk(
    'product/filters',
    async ({session, attributeSetIDs, categoryID}, {rejectWithValue, getState, dispatch}) => {
        try {
            const filtersToFetch = getRequiredFilterIDs(attributeSetIDs, getState().product);
            if(filtersToFetch?.length > 0 || categoryID > 0) {
                const filters = await getFilters(session, filtersToFetch, categoryID);
                return {filters};
            } else {
                return { filters: [] }
            }
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)

export const getProductBrandInfo = createAsyncThunk(
    'product/branding',
    async ({session, productIDs}, {rejectWithValue, dispatch}) => {
        try {
            console.log("getting branding")
            const req = request.model(api.MODEL.BRAND_BANK)
                .filter(condition.in("M_Product_ID", ...productIDs))
                .filter(condition.eq("isActive", "true"));

            const branding = await api.getAllRequestPages(req, session);
            const productToItem = mapBrandingByProduct(productIDs, branding);
            return { productToItem }
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error);
        }
    }
)