import {extendBuilder} from "../setup/reduxRequestHandler";
import {getPromotionItems} from "../action/promotionActions";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: {},
    error: {},

    pricePromotion: null,
}

const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(getPromotionItems, "promotion", (state, { payload }) => {
            const promoMap = {};
            for(const item of payload.items) {
                const campaign = item.Name;
                if(!(campaign in promoMap)){
                    promoMap[campaign] = [];
                }
                promoMap[campaign].push(item);
            }
            state.pricePromotion = promoMap;
        })

}

export const promotionSlice = createSlice({
    name: "promotion",
    initialState,
    extraReducers: buildExtraReducers
})

export default promotionSlice.reducer;
