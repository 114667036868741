import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {refreshLogin} from "../../../../redux/action/authActions";
import {sessionTimeOut} from "../../../../redux/slice/authSlice";
import {reportError} from "../../../../redux/slice/errorReportSlice";
import ErrorReport from "../../../logic/error/ErrorReport";


const isUnauthorized = (error) => {
    return error?.response?.data?.message === "Unauthorized";
}

const useAuthRequest = () => {

    const dispatch = useDispatch();
    const [ requestStatus, setRequestStatus ] = useState({});
    const [ pendingRequest, setPendingRequest ] = useState(null);
    const {
        refreshing,
        session
    } = useSelector(state => state.auth);
    const {
        loading,
        error,
        result
    } = requestStatus;

    const displayErrorReport = (error) => {
        const code = crypto.randomUUID();
        const errorMsg = ErrorReport.getUserMessage(error);
        dispatch(reportError({ code: code, error: errorMsg }));
    }


    const beforeRequest = () => {
        setRequestStatus({
            loading: true,
            error: null,
            result: null,
            pendingRefresh: null,
        })
    }

    const afterFailure = (error) => {
        setRequestStatus({
            loading: false,
            error,
            result: null,
            pendingRefresh: null,
        });
        displayErrorReport(error);
    }

    const afterSuccess = (result, optionalTask) => {
        setRequestStatus({
            loading: false,
            error: false,
            result,
            pendingRefresh: null,
        });

        if(optionalTask)
            optionalTask(result);
    }

    const onRefreshRequired = (request, optionalTask) => {
        setPendingRequest({ request, optionalTask });
        if(!refreshing) {
            dispatch(refreshLogin());
        }
    }

    const runSecondAttempt = async () => {
        try {
            const result = await pendingRequest.request();
            afterSuccess(result, pendingRequest.optionalTask);
        } catch (error) {
            if(isUnauthorized) {
                dispatch(sessionTimeOut());
            }
            afterFailure(error);
        }
    }

    if(!refreshing && pendingRequest && pendingRequest.request) {
        setPendingRequest(null);
        void runSecondAttempt();
    }

    const sendRequest = async (request, optionalTask) => {
        try {
            const result = await request(session);
            afterSuccess(result, optionalTask);
        } catch (error) {
            if(isUnauthorized(error)) {
                onRefreshRequired(request, optionalTask);
            } else {
                afterFailure(error);
            }
        }
    }

    const sendIt = (request, optionalTask) => {
        beforeRequest();
        void sendRequest(request, optionalTask);
    }

    return {
        loading,
        error,
        result,
        sendIt
    }

}

export default useAuthRequest;