import {Button} from "../../general/input/Button";
import TextArea from "../../general/input/TextArea";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createHelpDeskRequest} from "../../../redux/action/helpDeskActions";
import { v4 as uuidV4 } from "uuid";

const HelpDeskRequest = ({item}) => {

    const dispatch = useDispatch();

    const [ userInput, setUserInput ] = useState();
    const [ warning, setWarning ] = useState();
    const [ trackingID ] = useState(uuidV4())

    const { session } = useSelector(
        state => state.auth
    )
    const { loading, createdRequest } = useSelector(
        state => state.helpDesk
    )

    const onBlur = (e) => {
        const input = e.target.value;
        setUserInput(input)
        setWarning(null);
    }

    console.log(trackingID)

    const submitted = createdRequest[trackingID];
    const buttonClass = loading.request ? "loading" : submitted ? "inactive" : "";

    const onSubmit = () => {
        if(!userInput || userInput.length === 0) {
            setWarning("Input box cannot be empty")
        } if(!submitted) {
            dispatch(createHelpDeskRequest(
                {
                    session,
                    userRequest: {
                        "AD_Role_ID" : {
                            id: 1000000
                        },
                        "R_RequestType_ID" : {
                            "id" : item.R_RequestType_ID.id,
                            "tableName": "R_RequestType"
                        },
                        summary: userInput
                    },
                    trackingID
                }))
        }
    }

    return (
        <div className="tile help-desk-item">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-header-p">{item.Name}</p>
            </div>
            <div className="help-desk-tile-body">
                {!submitted &&
                    <>
                        {item.Description &&
                            <p>{item.Description}</p>}
                        {warning &&
                            <p className="form-warning">{warning}</p>
                        }
                        <TextArea onBlur={onBlur} />
                    </>
                }
                {submitted &&
                    <p>Thank you for submitting your request. We will get back to you as soon as possible!</p>
                }
                <Button className={buttonClass} onClick={onSubmit}>Submit</Button>
            </div>
        </div>
    )
}

export default HelpDeskRequest;