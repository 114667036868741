import {useDispatch, useSelector} from "react-redux";
import HelpDeskOverview from "../../../account/helpDesk/HelpDeskOverview";
import {getHelpDeskItems} from "../../../../redux/action/helpDeskActions";

const AccountLayoutGetHelp = () => {

    const dispatch = useDispatch();
    const { session } = useSelector(
        state => state.auth
    )
    const { loading, error, helpDeskItems } = useSelector(
        state => state.helpDesk
    )

    if(!helpDeskItems && !loading.items && !error.items) {
        dispatch(getHelpDeskItems({session}));
    }

    return (
        <HelpDeskOverview helpDeskItems={helpDeskItems} />
    )

}

export default AccountLayoutGetHelp;
