import "./footer.css"
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import useLogOut from "../state/account/useLogOut";

const SocialLink = ({link, name, iconSrc}) => {

    const leaveTo = (link) => () => {
        window.location.href = link;
    }

    return (
        <div className="social-link" onClick={leaveTo(link)}>
            <img src={iconSrc} alt="social media icon" />
            <p>{name}</p>
        </div>
    )

}

const Footer = () => {

    const { session } = useSelector(state => state.auth)

    const logOut = useLogOut();
    const navigate = useNavigate();
    const goTo = (link) => () => {
        navigate(link);
    }

    return (
        <div className="footer">
            <div className="footer-item-container">
                <div className="footer-items">
                    <h1>Customer Service</h1>
                    <p onClick={goTo("/account/overview")}>My Account</p>
                    <p onClick={goTo("/about/termsAndConditions")}>Returns Policy</p>
                    <p onClick={goTo("/about/termsAndConditions")}>Payment Options</p>
                    <p onClick={goTo("/about/privacy")}>Privacy Statement</p>
                </div>
                <div className="footer-items">
                    <h1>Contact Us</h1>
                    <p onClick={goTo("/account/helpDesk")}>Help Desk</p>
                    <p onClick={goTo("/contact/generalEnquiry")}>General Enquiry</p>
                    <p onClick={goTo("/contact/webFeedback")}>Website Feedback</p>
                </div>
                <div className="footer-items">
                    <h1>Information</h1>
                    <p onClick={goTo("/about/aboutUs")}>About us</p>
                </div>
                {session && session.bPartnerID &&
                    <div className="footer-items">
                        <h1>Account</h1>
                        <p onClick={() => logOut()}>Log Out</p>
                    </div>
                }
            </div>
            <div className="footer-socials">
                <h1>Find us on social media</h1>
                <div className="social-links">
                    <SocialLink
                        name="facebook"
                        link="https://www.facebook.com/suthbros"
                        iconSrc="/assets/facebook.svg"
                    />
                    <SocialLink
                        name="X (formerly twitter)"
                        link="https://twitter.com/suthbros"
                        iconSrc="/assets/twitter.svg"
                    />
                </div>
            </div>
            <div className="footer-credits-container">
                <p className="footer-credits">©2024 <span className="footer-highlight">Sutherland Brothers Ltd.</span> All rights reserved.</p>
                <p className="footer-credits">Sutherland Brothers Ltd is a Company registered in Scotland, No. SC090161. VAT No. 266 1208 71</p>
                <p className="footer-credits">Website designed and developed by <span className="footer-highlight">Bigdog ERP</span>.</p>
            </div>
        </div>
    )

}

export default Footer;