import {createAsyncThunk} from "@reduxjs/toolkit";
import {condition, request} from "../../res/rest/restRequest";
import {api} from "../../res/rest/api";
import axios from "axios";
import {env} from "../../res/config/env";
import {getBPartnerUsers} from "../../api/request/account/userRequest";
import {
    getActiveBackOrderRequest,
    getBankingRequest,
    getCreditStatusRequest
} from "../../api/request/account/bPartnerRequest";
import {idempiereProcess} from "../../res/rest/process";

const auth = (session) => {
    return {
        headers: {
            Authorization: `Bearer ` + session.authToken
        }
    }
}

const getSalesRegion = async(session, regionID) => {
    const req = request.model(api.MODEL.C_SALES_REGION)
        .expand("x_deliveryPreference")
        .filter(condition.eq("C_SalesRegion_ID", regionID))
        .buildRequest();

    const url = env.API_URL + req;
    const response = await axios.get(url, auth(session));
    return response.data.records[0];
}

const attachSalesRegionToLocations = async(session, locations) => {
    const salesRegions = {};

    for(const location of locations) {
        const regionID = location["C_SalesRegion_ID"]
        if(regionID) {
            const region = salesRegions[regionID.id] ? salesRegions[regionID.id] :
                await getSalesRegion(session, regionID.id);
            salesRegions[regionID.id] = region;
            location["C_SalesRegion_ID"] = region;
        }
    }
}

const getLocations = async(session) => {
    const req = request.model(api.MODEL.BP_LOCATION)
        .expand("C_Location_ID", "C_SalesRegion_ID")
        .filter(condition.eq("C_BPartner_ID", session.bPartnerID))
        .buildRequest();

    const url = env.API_URL + req;
    const response = await axios.get(url, auth(session));
    const locations = response.data.records;
    await attachSalesRegionToLocations(session, locations)

   return locations;
}

const createAddress = async(session, address) => {
    const req = request.model(api.MODEL.C_LOCATION).buildRequest();
    const body = {
        "address1": address.address1,
        "address2": address.address2,
        "city": address.city,
        "postal": address.postal,
        "C_Country_ID": 333
    }

    const response = await axios.post(env.API_URL + req, body, api.auth(session));
    return response.data;
}

const createBPLocation = async(session, address, location) => {
    const req = request.model(api.MODEL.BP_LOCATION).buildRequest();
    const body = {
        "name": address.addressName,
        "phone": address.phone,
        "C_BPartner_ID": session.bPartnerID,
        "C_Location_ID": location.id,
        "isBillTo": "Y",
        "isShipTo": "Y",
        "isPayFrom": "Y",
        "isRemitTo": "Y"
    }

    const response = await axios.post(env.API_URL + req, body, api.auth(session));
    return response.data;
}

export const getBPLocations = createAsyncThunk(
    'account/getBPAddress',
    async({session}, {rejectWithValue, dispatch}) => {

        try {
            const locations = await getLocations(session);
            return { locations };
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }

    }
)

export const createLocation = createAsyncThunk(
    'account/addLocation',
    async({session, addressInput}, {rejectWithValue, dispatch}) => {
        try {

            const address = await createAddress(session, addressInput);
            const bpLocation = await createBPLocation(session, addressInput, address)
            bpLocation["C_Location_ID"] = address;
            await attachSalesRegionToLocations(session, [bpLocation]);
            return { bpLocation };

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getOrderLines = createAsyncThunk(
    "account/getOrderLines",
    async ({session, orderID}, {rejectWithValue, dispatch}) => {
        try {

            const req = request.model("web_ordering_orderline_v")
                .filter(condition.eq("c_bpartner_id", session.bPartnerID))
                .filter(condition.eq("c_order_id", orderID))
                .expand("M_Product_ID", "C_UOM_ID", "C_Charge_ID")
                .hostURL(env.API_URL)
                .buildRequest();

            const resp = await axios.get(req, api.auth(session));

            const orderLines = resp.data.records;
            orderLines.sort((a,b) => a.Line - b.Line);
            return {orderID, orderLines};

        } catch (error) {
            console.log("error", error)
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getColleagues = createAsyncThunk(
    "account/colleagues",
    async({ session }, {rejectWithValue, dispatch}) => {
        try {
            const req = getBPartnerUsers(session.bPartnerID)
                .buildRequest(env.API_URL);

            const response = await axios.get(req, api.auth(session));
            return { colleagues: response.data.records };
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getBanking = createAsyncThunk(
    "account/banking",
    async({ session }, {rejectWithValue, dispatch}) => {
        try {
            const req = getBankingRequest(session.bPartnerID)
                .buildRequest(env.API_URL);

            const response = await axios.get(req, api.auth(session));
            return {banking: response.data.records};
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getCreditStatus = createAsyncThunk(
    "account/creditStatus",
    async({ session }, {rejectWithValue, dispatch}) => {
        try {
            const req = getCreditStatusRequest(session.bPartnerID)
                .buildRequest(env.API_URL);

            const response = await axios.get(req, api.auth(session));
            return {creditStatus: response.data.records[0]};
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const getActiveBackOrders = createAsyncThunk(
    "account/backOrders",
    async ({ session }, { rejectWithValue, dispatch }) => {
        try {
            const req = getActiveBackOrderRequest(session.bPartnerID)
                .buildRequest(env.API_URL);

            const response = await axios.get(req, api.auth(session));
            return { backOrderLines: response.data.records }
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const cancelBackOrder = createAsyncThunk(
    "account/cancelBackOrder",
    async ({ session, orderLineID }, { rejectWithValue, dispatch }) => {
        try {

            const req = request.process(api.PROCESS.BACK_ORDER_CANCEL)
                .param("C_OrderLine_ID", orderLineID);
            const url = env.API_URL + req.buildRequest();
            const resp = await axios.post(url, req.buildRequestBody(), api.auth(session));
            const data = idempiereProcess.handleResponseThrowingException(resp);
            return {orderLineID, response: data.summary};

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

export const updateLocation = createAsyncThunk(
    "account/updateLocation",
    async ({ session, locationID, updates },  { rejectWithValue, dispatch }) => {
        try {
            const url = request.model(api.MODEL.BP_LOCATION)
                .id(locationID)
                .buildRequest(env.API_URL);
            const response = await axios.put(url, updates, api.auth(session));
            return { updatedLoc: response.data }
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)
