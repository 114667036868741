
const getBusinessPartner = (data) => {

    const bp = {};

    bp.getPaymentRule = () => {
        return data.PaymentRule;
    }

    return bp;
}

export default getBusinessPartner;