import useSolrProduct from "./useSolrProduct";
import {useState} from "react";
import productSearchParamsBuilder from "../../../logic/product/productSearchParams";

const useProductIDPage = (pageNo, productIDs, autoLoad = true) => {

    const {
        error,
        loading,
        products,
        productCount,
        getProducts,
    } = useSolrProduct();

    const [ fetchedPage, setFetchedPage ] = useState(null);

    const shouldFetchPage = () => {
        return autoLoad &&
            Boolean(productIDs) && (
            !fetchedPage || (
                fetchedPage.pageNo !== pageNo ||
                fetchedPage.productIDs.length !== productIDs.length ||
                fetchedPage.productIDs.find((id, i) => id !== productIDs[i])
            ))
    }

    const fetchPage = () => {
        setFetchedPage({pageNo, productIDs});

        const params = productSearchParamsBuilder()
            .pageSize(20)
            .pageNo(pageNo)
            .productIDs(productIDs)
            .getParams();

        getProducts(params);
    }

    if(shouldFetchPage()) {
        fetchPage();
    }

    const pageCount = (Math.ceil(productCount / 20)) + 1

    return {
        loading,
        error,
        products,
        pageCount
    }
}

export default useProductIDPage;
