import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {api} from "../../res/rest/api";
import {request} from "../../res/rest/restRequest";
import {env} from "../../res/config/env";
import {idempiereProcess} from "../../res/rest/process";

const TEMP_BACKEND = "http://localhost:8557";
const URL_RETURN = TEMP_BACKEND + "/redirect";

const getFailedAuthMessage = (responseData) => {

    if(responseData.paresStatus === "R" && responseData.veresEnrolled === "Y") {
        return "A problem has occurred. We cannot accept this payment, please try another form of payment. "
    }

    return "Card authentication failed. Please check all card details are correct.";
}

const runStep = async (session, step, payload) => {

    const req = request.process(api.PROCESS.PAYMENT_HANDLER)
        .paramObject("payload", payload)
        .param("step",step);
    const url = env.API_URL + req.buildRequest();
    const resp = await axios.post(url, req.buildRequestBody(), api.auth(session));

    const data = idempiereProcess.handleResponseThrowingException(resp);
    const summary = data.summary;
    const errorID = "ApiError:";

    if(summary.startsWith(errorID)) {
        return {apiError: summary.slice(errorID.length)}
    } else if(summary === "orderHasPayment") {
        return {success: true};
    } else {
        const responseData = JSON.parse(data.summary);
        if(responseData.status && responseData.status === "AUTHENTICATION_FAILED") {
            return { apiError: getFailedAuthMessage(responseData) }
        } else {
            return responseData;
        }
    }
}

export const step1 = createAsyncThunk(
    'payment/step1',
    async ({session, details}, {dispatch, rejectWithValue}) => {

        try {
            return await runStep(session, "step1", details);

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session)
        }
    }
)

export const step3 = createAsyncThunk(
    'payment/step3',
    async ({session, details}, {dispatch, rejectWithValue}) => {

        try {
            const data = {
                ...details,
                returnUrl: URL_RETURN
            }

            return await runStep(session, "step3", data);

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session)
        }
    }
)

export const step5 = createAsyncThunk(
    'payment/step5',
    async ({session, details}, {rejectWithValue, dispatch}) => {

        try {
            return await runStep(session, "step5", details);
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session)
        }
    }
)
