import {useDispatch, useSelector} from "react-redux";
import {createLocation, getBPLocations, updateLocation} from "../../../redux/action/accountActions";

const KEY_UPDATE_LOC = "update_loc_";

const useAddress = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(
        state => state.auth
    )
    const { locations, loading, error } = useSelector(
        state => state.account
    )

    if(session?.bPartnerID && !locations && !loading.location && !error.location) {
        dispatch(getBPLocations({session}))
    }

    const getLocationUpdateRecord = (location, changes) => {
        const updateData = {}

        const recordChange = ({column, record : passedRecord}) => {
            const record = passedRecord ? passedRecord : column;
            if(column in changes && changes[column] !== location[record]) {
                updateData[record] = changes[column];
            }
        }

        if("isActive" in changes) {
            updateData["isActive"] = false;
        } else {
            recordChange({column: "note", record: "X_DeliveryNote"});
            recordChange({column: "phone"})
            recordChange({column: "addressName", record: "Name"});
        }
        return updateData;
    }

    const handleUpdateLocation = (location, changes) => {
        const updates = getLocationUpdateRecord(location, changes);
        const somethingHasChanged = Object.keys(updates).length > 0;

        if(somethingHasChanged) {
            const locationID = location.id;
            const dispatchKey = KEY_UPDATE_LOC + locationID;
            dispatch(updateLocation({ session, locationID, updates, dispatchKey }))
        }
    }

    const getUpdatingLocs = () => {
        const updatingLocs = {}
        for(const key of Object.keys(loading)) {
            if(key.startsWith(KEY_UPDATE_LOC) && loading[key]) {
                updatingLocs[Number(key.slice(KEY_UPDATE_LOC.length))] = true;
            }
        }
        return updatingLocs;
    }

    const handleCreateLocation = (addressInput) => {
        const dispatchKey = KEY_UPDATE_LOC + 0;
        dispatch(createLocation({ session, addressInput, dispatchKey }));
    }

    return {
        locations,
        loading,
        error,
        createLocation: handleCreateLocation,
        updatingLocs: getUpdatingLocs(),
        updateLocation: handleUpdateLocation,
        loadingLocations: loading.location,
        loadingLocationUpdate: loading.createLocation
    }

}

export default useAddress;