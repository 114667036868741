import {createSlice} from "@reduxjs/toolkit";
import {extendBuilder} from "../setup/reduxRequestHandler";
import {createHelpDeskRequest, getHelpDeskItems} from "../action/helpDeskActions";

const initialState = {
    loading: {},
    error: {},

    helpDeskItems: null,
    createdRequest: {},
}

const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(getHelpDeskItems, "items", (state, {payload}) => {
            state.helpDeskItems = payload;
        })
        .addStandardCase(createHelpDeskRequest, "request", (state, {payload}) => {
            state.createdRequest[payload.trackingID] = true;
        })

}

export const categorySlice = createSlice({
    name: 'helpDesk',
    initialState,
    reducers: {},
    extraReducers: buildExtraReducers
})

export default categorySlice.reducer;