import {useDispatch, useSelector} from "react-redux";
import {getColleagues} from "../../../redux/action/accountActions";

const useColleague = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(
        state => state.auth
    )
    const { colleagues, loading, error } = useSelector(
        state => state.account
    )

    if(!colleagues && !loading.colleague && !error.colleague) {
        dispatch(getColleagues({session}))
    }

    return {
        colleagues,
        loading,
        error,
        loadingColleague: loading.colleague,
        errorColleague: error.colleague
    }

}

export default useColleague;