import {useSelector} from "react-redux";
import usualsRequest from "../../rest/request/product/usualsRequest";
import {env} from "../../../../res/config/env";
import {useState} from "react";
import useAuthRequest from "../request/useAuthRequest";

const compareProducts = (thisProduct,thatProduct) => {
    return thatProduct.purchase_instance_count - thisProduct.purchase_instance_count;
}

const compareCategories = (sortedProducts) => (thisCategory, thatCategory) => {
    const thisHighestScorer = sortedProducts[thisCategory.id][0];
    const thatHighestScorer = sortedProducts[thatCategory.id][0];
    return thatHighestScorer.purchase_instance_count - thisHighestScorer.purchase_instance_count;
}

const sortedUsuals = (result) => {
    if(!result) return;

    const sorted = {};

    for(const category of result.categories) {
        sorted[category.id] = result[category.id].sort(compareProducts);
    }

    sorted.categories = result.categories.sort(compareCategories(sorted));
    return sorted;
}

const useUsuals = () => {

    const { session } = useSelector(state => state.auth);

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const [ usuals, setUsuals ] = useState();

    const isLoggedIn = Boolean(session?.authToken) && Boolean(session?.userID > 0);

    const onUsualsReceived = (result) => {
        setUsuals(sortedUsuals(result));
    }

    if(isLoggedIn && !loading && !error && !result) {
        const request = usualsRequest()
            .setBPartnerID(session.bPartnerID)
            .setUserID(session.userID)
            .host(env.API_URL);

        sendIt(request.sendRequest, onUsualsReceived);
    }

    return {
        loading,
        error,
        usuals
    }
}

export default useUsuals;
