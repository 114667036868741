import {Button} from "../general/input/Button";
import {useNavigate} from "react-router-dom";

const FeaturePromoAccount = () => {

    const navigate = useNavigate();
    const redirectToAccountPage = () => {
        navigate("/account");
    }

    return (
        <div className="feature-promo alt">
            <div className="feature-promo-img-container">
                <img alt="" src="/assets/handshake.png" />
            </div>
            <div className="feature-promo-content">
                <h1>Take control of your account</h1>
                <p>Manage your account from our account page. Our overview contains all of the important information you
                want to see, from request updates to how far away your driver is. You can also use our account page to
                do everything from adding new users to your business, to creating returns, to making payments.</p>
                <Button onClick={redirectToAccountPage}>Manage Your Account</Button>
            </div>
        </div>
    )
}

export default FeaturePromoAccount;