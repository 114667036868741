import React from "react";
import ImageLazyLoad from "../../../general/loading/ImageLazyLoad";
import "../../../account/style/ordering.css"
import {uomService} from "../../../../res/dataServices/uom";
import {formatDisplayPrice} from "../../../../res/dataServices/pricing";

const DetailItem = ({heading, headingStyle, body}) => {

    return (
        <div>
            <p className="placed-order-detail-h" style={{...headingStyle}}>{heading}</p>
            <p className="placed-order-detail-b">{body}</p>
        </div>
    )
}

const LineOrder = ({ orderLine }) => {

    const product = orderLine["M_Product_ID"];
    const productImg  = product?.ImageURL;
    const productName = product.Name;
    const productCode = product.Value;
    const uomQuantity = uomService.uomQuantity(orderLine["C_UOM_ID"]);
    const ordered = uomQuantity.getDisplayName(orderLine.QtyEntered);
    const price = formatDisplayPrice(orderLine.LineNetAmt);

    return (
        <div className="placed-order-line-body">
            <ImageLazyLoad
                className={"placed-order-line-img"}
                src={productImg}
                alt="Product"
            />
            <DetailItem heading={productCode}
                        headingStyle={{fontSize: "14px", fontWeight: "bold", color:"var(--primary)"}}
                        body={productName}/>
            <DetailItem heading="Quantity" body={ordered}/>
            <DetailItem heading={"Total"} body={price}/>
        </div>
    )

}

export default LineOrder;