
const Banner = () => {

    // const { session } = useSelector(
    //     state => state.auth
    // )
    //
    // const navigation = useNavigate();
    //
    // const onHelpClick = () => {
    //     navigation("/account/help/desk");
    // }

    return (
        <div className="header-banner">
            {/*<div className="header-banner-content">*/}
            {/*    <div className="header-banner-actions">*/}
            {/*        <HelpIcon style={{cursor: "pointer"}} onClick={onHelpClick} />*/}
            {/*        <p style={{cursor: "pointer"}} onClick={onHelpClick}>Help</p>*/}
            {/*    </div>*/}

            {/*    <div className="header-banner-info">*/}
            {/*        {session?.userID &&*/}
            {/*            <>*/}
            {/*                <LocIcon />*/}
            {/*                <p>{session?.orgName}</p>*/}
            {/*                /!*<BusinessIcon />*!/*/}
            {/*                /!*<p>{session?.businessName}</p>*!/*/}
            {/*                /!*<AccountIcon/>*!/*/}
            {/*                /!*<p>{session?.userName}</p>*!/*/}
            {/*            </>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )

}

export default Banner;