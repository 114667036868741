import {extendBuilder} from "../setup/reduxRequestHandler";
import {createSlice} from "@reduxjs/toolkit";
import {requestPasswordReset, resetPassword} from "../action/passwordActions";

const initialState = {
    loading: {},
    error: {},

    requestResult: null,
    resetResult: {},
}

const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(requestPasswordReset, "request", (state, { payload }) => {
            state.requestResult = payload;
        })
        .addStandardCase(resetPassword, "reset", (state, { meta, payload }) => {
            state.resetResult[meta.arg.token] = payload;
        })

}

export const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {},
    extraReducers: buildExtraReducers
})

export default passwordSlice.reducer;