import {useState} from "react";
import Input from "../../../general/input/Input";
import {Button} from "../../../general/input/Button";
import Popup from "../../../general/popup/Popup";
import TextArea from "../../../general/input/TextArea";

const NAME      = "addressName";
const ADDRESS1  = "address1";
const ADDRESS2  = "address2";
const CITY      = "city";
const COUNTY    = "county";
const POSTCODE  = "postal";
const PHONE     = "phone";
const NOTE      = "X_DeliveryNote"


const getDefaultInput = (location) => {
    if(!location) {
        return {};
    } else {
        const address = location["C_Location_ID"];
        return {
            [NAME]: location.Name,
            [ADDRESS1]: address.Address1,
            [ADDRESS2]: address.Address2,
            [CITY]: address.City,
            [POSTCODE]: address.Postal,
            [PHONE]: location.Phone,
            [NOTE]: location.X_DeliveryNote,
        }
    }
}

const AddressInput = ({ location, onSave, onInactivate : passedOnInactivate, onBack, updating }) => {

    const [address, setAddress] = useState(getDefaultInput(location));
    const [ confirmDelete, setConfirmDelete ] = useState(false);

    const onInputChange = (key) => (event) => {
        onValueChange(key, event.target.value);
    }

    const onValueChange = (key, value) => {
        const updatedAddress = {...address, [key]: value};
        setAddress(updatedAddress);
    }

    const onInactivate = () => {
        setConfirmDelete(false);
        passedOnInactivate(location);
    }

    const handleSave = () => {
        if(!updating) {
            onSave(location, address)
        }
    }

    const locationExists = location && location.id > 0;

    return (
        <div className="address-input-2">
            <p>Address Name</p>
            <Input
                hint="Name for this address"
                defaultValue={address[NAME]}
                onChange={onInputChange(NAME)} />
            <p>Address Line 1</p>
            <Input
                disabled={locationExists}
                defaultValue={address[ADDRESS1]}
                onChange={onInputChange(ADDRESS1)}/>
            <p>Address Line 2</p>
            <Input
                disabled={locationExists}
                defaultValue={address[ADDRESS2]}
                onChange={onInputChange(ADDRESS2)}/>
            <p>Town/ City</p>
            <Input
                disabled={locationExists}
                defaultValue={address[CITY]}
                onChange={onInputChange(CITY)}/>
            <p>County</p>
            <Input
                disabled={locationExists}
                defaultValue={address[COUNTY]}
                onChange={onInputChange(COUNTY)}/>
            <p>Postcode</p>
            <Input
                disabled={locationExists}
                defaultValue={address[POSTCODE]}
                onChange={onInputChange(POSTCODE)}/>
            <p>Phone Number</p>
            <Input
                defaultValue={address[PHONE]}
                onChange={onInputChange(PHONE)}/>
            <p>Delivery Note</p>
            <TextArea
                defaultValue={address[NOTE]}
                onChange={onInputChange(NOTE)}
            />
            {locationExists &&
                <Button className="button_clear" onClick={() => setConfirmDelete(true)}>Inactivate</Button>
            }
            <Button className="button_clear" onClick={onBack}>Back</Button>
            <Button className={updating ? "button-loading" : ""} onClick={handleSave}>Save</Button>
            {confirmDelete &&
                <Popup>
                    <div className="address-delete-dialog">
                        <h3>Inactivate {location.Name}</h3>
                        <p>Are you sure you want to set this address to inactive?</p>
                        <Button className="button_clear" onClick={() => setConfirmDelete(false)}>Back</Button>
                        <Button onClick={onInactivate}>Inactivate</Button>
                    </div>
                </Popup>
            }
        </div>
    )

}

export default AddressInput;
