import {useNavigate, useParams} from "react-router-dom";
import useProductIDPage from "../../../services/state/product/useProductIDPage";
import PageLayout from "../../../../components/page/PageLayout";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import ProductLayout from "../../product/ProductLayout";
import ProductFilter from "../../product/ProductFilter";
import Pagination from "../../navigation/Pagination";
import usePromotions from "../../../services/state/product/usePromotions";
import {useEffect, useMemo, useState} from "react";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";
import ProductPromotionList from "../../product/ProductPromotionList";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Promotion", nav: "/clearance", selected:true} ]

const PagePromotion = () => {

    const navigate = useNavigate();

    const { pageNo : pageNoParam } = useParams();

    const pageNo = pageNoParam && !isNaN(Number(pageNoParam)) ? Number(pageNoParam) : 1;
    const [ currentPageNo, setCurrentPageNo ] = useState(pageNo);
    useEffect(() => {
        if(currentPageNo !== pageNo) {
            setCurrentPageNo(pageNo)
            window.scrollTo(0, 0)
        }
    }, [currentPageNo, pageNo])

    const {
        loading : promotionLoading,
        promotions
    } = usePromotions();

    const productIDs = useMemo(
        () => promotions?.map(promo => promo.id),
        [promotions]
    );

    const {
        loading: productsLoading,
        products,
        pageCount,
    } = useProductIDPage(pageNo, productIDs);

    const onPageChange = (pageNo) => {
        navigate("/promotion/" + pageNo);
    }

    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo], () => `/promotion/${pageNo}`);

    return (
        <PageLayout
            requireSignIn={true}
        >
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <div className="product-layout-filters">
                        <ProductFilter
                            filters={{}}
                        />
                    </div>
                    <ProductPromotionList
                        promotions={promotions}
                        products={products}
                        loading={productsLoading || promotionLoading}
                    />
                </ProductLayout>
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount - 1}
                    onPageRequested={onPageChange}
                    loading={productsLoading || promotionLoading}
                />
            </div>
        </PageLayout>
    )
}

export default PagePromotion;