import {useDispatch, useSelector} from "react-redux";
import {requestPasswordReset, resetPassword} from "../../../redux/action/passwordActions";
import ReactGA from "react-ga4";

const trackResetSent = () => {
    const eventParams = {
        event_name: "password_reset",
        category: "Auth",
        label: "reset_sent"
    };

    ReactGA.event("password_reset", eventParams);
}

const usePasswordReset = () => {

    const dispatch = useDispatch();
    const { loading, error, resetResult, requestResult } = useSelector(
        state => state.password
    )

    const sendResetRequest = (email) => {
        if(!loading.request) {
            trackResetSent();
            dispatch(requestPasswordReset({email}));
        }
    }

    const sendPasswordReset = (token, newPassword) => {
        if(!loading.reset) {
            dispatch(resetPassword({token, newPassword}))
        }
    }

    return {
        loadingRequest: loading.request,
        errorOnRequest: error.request,
        loadingReset: loading.reset,
        errorReset: error.reset,
        resetResult,
        requestResult,
        sendResetRequest,
        sendPasswordReset
    }

}

export default usePasswordReset;