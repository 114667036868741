import PageLayout from "../../../../components/page/PageLayout";
import usePurchaseOrderConfirmation from "../../../services/state/vendor/usePurchaseOrderConfirmation";
import {useParams} from "react-router-dom";
import PurchaseOrder from "../../vendor/PurchaseOrder";
import '../../style/vendor/purchaseOrder.css'
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import ReactGA from "react-ga4";

const trackPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}

const PagePurchaseOrderConfirmation = () => {

    const { token } = useParams();
    const poManger = usePurchaseOrderConfirmation(token);

    useValueChangeListener(trackPageView, [ token ], [ null ])

    return (
        <PageLayout>
            <div className="page-content page-min-height">
                <div className="purchase-order-content">
                    <p className="purchase-order-page-heading">Purchase Order Acknowledgement</p>
                    <PurchaseOrder
                        poManager={poManger}
                    />
                </div>
            </div>
        </PageLayout>
    )

}

export default PagePurchaseOrderConfirmation;