import RadioSelect from "../../general/input/RadioSelect";
import {useState} from "react";
import CreditCardInput from "../../../newStructure/component/payment/CreditCardInput";
import AcceptedCards from "../../payment/AcceptedCards";
import {cardInfo} from "../../../res/dataServices/cardInfo";
import {useDispatch, useSelector} from "react-redux";
import {onCardInput} from "../../../redux/slice/paymentSlice";
import {updateOrder} from "../../../redux/action/cartActions";
import useBanking from "../../state/account/useBanking";
import {idempiereDate} from "../../../res/dataServices/idempiereDate";
import CardDisplay from "../../payment/CardDisplay";
import {RadioSelectAny, RadioSelectAnyOption} from "../../general/input/RadioSelectAny";
import useBusinessPartner from "../../../newStructure/services/state/account/useBusinessPartner";

export const paymentOptions = {

    K: {
        type:'K',
        displayText:'Debit or Credit card'
    },
    D: {
        type:'D',
        displayText: 'Direct Debit'
    },
    T: {
        type:'T',
        displayText: 'Pay with account'
    }

}

const getCardID = (bank) => {
    return bank.CreditCardType.id
        + "_" + bank.CreditCardNumber
        + "_" + bank.CreditCardExpMM
        + "_" + bank.CreditCardExpYY
        + "_" + bank.A_City
        + "_" + bank.A_Street
        + "_" + bank.A_Zip
        + "_" + bank.A_Name
}

const splitName = (value) => {
    const namesArray = value.trim().split(" ");
    const lastName = namesArray.length > 1 ? namesArray.pop() : '';
    const firstName = namesArray.join(" ");
    return [firstName, lastName];
}

const getSavedCards = (banking) => {

    const isCardExpired = (expireMonth, expireYear) => {
        const currentDate = new Date();
        const expiryDate = new Date(expireYear, expireMonth -1)
        return expiryDate < currentDate;
    }

    const sortedBanks = banking ? banking.map(i => i).sort((thisBank, thatBank) =>
        idempiereDate.getDate(thisBank.Created) > idempiereDate.getDate(thatBank.Created)
    ) : [];

    const saved = {};
    for(const bank of sortedBanks) {
        if(bank.CreditCardNumber && bank.CreditCardNumber.length > 0) {
            if(!isCardExpired(bank.CreditCardExpMM, bank.CreditCardExpYY)) {
                saved[getCardID(bank)] = bank;
            }
        }
    }

    return Object.values(saved);
}

const getPaymentOptions = (businessPartner) => {
    if(!businessPartner) {
        return [paymentOptions.K];
    } else if(businessPartner.getPaymentRule()?.id === "D") {
        return [paymentOptions.D, paymentOptions.K];
    } else {
        return [paymentOptions.T, paymentOptions.K];
    }
}

const InputPayment = ({cart, setCardInput}) => {

    const { session } = useSelector(
        state => state.auth
    )
    const { cardInput } = useSelector(
        state => state.payment
    )
    const { businessPartner } = useBusinessPartner();
    const dispatch = useDispatch();

    const { banking } = useBanking();
    const savedCards =  getSavedCards(banking);

    const cardOptions = ["New Card", "Saved Card"]
    const [ cardInputType, setCardInputType ] = useState(cardOptions[0])

    const orderID = cart.order.id;
    const paymentRule = cart.order.PaymentRule.id;

    const onOptionUpdated = (option) => {
        const optionID = option.type;
        if(optionID !== paymentOptions) {
            const toChange = {"PaymentRule" : {"id": optionID}}
            dispatch(updateOrder({session, orderID, toChange}))
        }
    }

    const onCardChange = (arg) => {
        dispatch(onCardInput({data: arg}));
        if(setCardInput) setCardInput(arg);
    }

    const onCardSelected = (bank) => {
        const [firstName, lastName] = splitName(bank.A_Name);
        onCardChange({
            bankID: bank.id,
            customerPaymentProfileID: bank.CustomerPaymentProfileID,
            expireMonth: bank.CreditCardExpMM,
            expireYear: bank.CreditCardExpYY,
            cardType: cardInfo.getCodeForIdempiereID(bank.CreditCardType.id),
            firstName,
            lastName,
            email: bank.A_Email
        })
    }
    const selectedCard = cardInput && cardInput.bankID ? cardInput.bankID : 0;

    const onInputTypeChanged = (option) => {
        onCardChange(null);
        setCardInputType(option);
    }



    return (
        <>
            <p className="form-sub-heading">Payment Method</p>
            <RadioSelect
                options={getPaymentOptions(businessPartner)}
                optionToText={o => o.displayText}
                onOptionSelected={onOptionUpdated}
                defaultOption={paymentRule ? paymentOptions[paymentRule] : null}
            />
            {(paymentRule && paymentRule === 'K') &&
                <>
                    <p className="form-sub-heading">Cards</p>
                    <RadioSelect
                        options={cardOptions}
                        optionToText={o => o}
                        onOptionSelected={onInputTypeChanged}
                        defaultOption={cardInputType}
                    />

                    {cardInputType === "Saved Card" ?
                        <>
                            <p className="form-sub-heading">Your Saved Cards</p>
                            <RadioSelectAny>
                                    {savedCards.map(c =>
                                        <RadioSelectAnyOption
                                            onChecked={() => onCardSelected(c)}
                                            checked={selectedCard === c.id}>
                                            <CardDisplay bank={c} />
                                        </RadioSelectAnyOption>
                                    )}
                            </RadioSelectAny>
                        </> : <>
                            <p className="form-sub-heading">New Card</p>
                            <CreditCardInput
                                onChange={onCardChange}
                                defaultCard={cardInput}
                            />
                            <AcceptedCards
                                acceptedTypes={cardInfo.acceptedCards}
                                selectedType={cardInput ? cardInput.type : null}
                            />
                        </>
                    }
                </>
            }
        </>
    )
}

export default InputPayment;
