import Input from "../general/input/Input";
import {Button} from "../general/input/Button";
import {useState} from "react";
import usePasswordReset from "../state/account/usePasswordReset";
import InputErrorContainer from "../general/input/InputWithError";
import ReactGA from "react-ga4";
import useValueChangeListener from "../../newStructure/services/state/general/useValueChangeListener";

const RES_INVALID = "tokenInvalid";

const trackPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}

const validatePassword = (password) => {
    if (password.length < 8) {
        return "Password must be at least 8 characters long";
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return "Password must contain at least one capital letter";
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return "Password must contain at least one lowercase letter";
    }

    // Check for at least one digit
    if (!/\d/.test(password)) {
        return "Password must contain at least one digit";
    }

    return null;
}

const ResetPasswordForm = ({ loading, resetPassword }) => {

    const [ validate, setValidate ] = useState({});
    const [ userInput, setUserInput ] = useState({})

    const onPasswordInput = (e) => {
        const input = e.target.value;
        const validateResult = validatePassword(input);
        setValidate({
            ...validate,
            password: validateResult
        })
        setUserInput({
            ...validate,
            pass: input
        });
    }

    const onConfirmInput = (e) => {
        const input = e.target.value;
        setValidate({
            ...validate,
            confirm: input !== userInput.pass ? "Must match password" : null
        })
        setUserInput({
            ...userInput,
            confirm: input
        })
    }

    const inputValid = userInput.pass && userInput.confirm && !validate.password && !validate.confirm;

    const onResetPassword = () => {
        if(inputValid && !loading) {
            resetPassword(userInput);
        }
    }

    return (
        <div className="password-reset-form">
            <p>Enter a new password for your account</p>
            <InputErrorContainer error={validate.password}>
                <Input
                    hint="New password"
                    onBlur={onPasswordInput}
                    type="password" />
            </InputErrorContainer>
            <InputErrorContainer error={validate.confirm}>
                <Input
                    hint="Confirm new password"
                    onBlur={onConfirmInput}
                    type="password" />
            </InputErrorContainer>
            <Button
                className={!inputValid ? "inactive" : loading ? "loading" : ""}
                onClick={onResetPassword}>
                Reset Password
            </Button>
            <div className="password-reset-requirements">
                <p>Passwords must be:</p>
                <p>At least 8 characters long</p>
                <p>Contain at least one digit</p>
                <p>Contain at least one upper chase character</p>
                <p>Contain at least one lower case character</p>
            </div>
        </div>
    )
}


const PasswordReset = ({ token }) => {

    const { loadingReset, sendPasswordReset, resetResult } = usePasswordReset();

    const result = resetResult[token];

    const resetPassword = (userInput) => {
        sendPasswordReset(token, userInput.pass);
    }

    useValueChangeListener(trackPageView, [ token ], [ null ]);

    return (
        <div className="password-reset-content">
            <div className="password-reset-content-format">
            {!result ?
                <>
                    <h1>Reset Account Password</h1>
                    <ResetPasswordForm resetPassword={resetPassword} loading={loadingReset} />
                </>
                : result === RES_INVALID ?
                    <div className="password-reset-result">
                        <h1>Oh No<span className="emphasis">!</span></h1>
                        <h2>Somethings gone <span className="emphasis">wrong</span>.</h2>
                        <div className="password-reset-result-info">
                            <p>Oops! Something went wrong with your password reset link. Here's
                                what might be happening:
                            </p>
                            <p>
                                <span className="emphasis">Token Timeout:</span> Our security measures are top-notch, and the
                                password reset link is only valid for one hour. If an hour has passed since you requested it
                                , you'll need to generate a new one
                            </p>
                            <p>
                                <span className="emphasis">Mistyped URL:</span> If you have typed the link, make sure it is
                                typed correctly. It's easy to miss a character or include extra spaces. Double-check, and
                                give it another shot. This wont be an issue if you have just clicked the link in your email.
                            </p>
                            <p>
                                <span className="emphasis">Still Not Working? Contact Support:</span> If you've tried the
                                above steps and are still having trouble, don't worry—we're here to help! Reach out to our
                                support team via the help desk, and we'll assist you in getting back into your account.
                            </p>
                            <p>
                                Remember, we're committed to your security, and these measures are in place to protect
                                your information. If you have any questions or concerns, feel free to contact our
                                support team.
                            </p>
                            <p>
                                Thank you for your understanding!
                            </p>
                        </div>
                    </div> :
                    <div className="result">
                        <div className="password-reset-result">
                            <h1>Success<span className="emphasis">!</span></h1>
                            <h2>Your password has been <span className="emphasis">changed</span>.</h2>
                            <div className="password-reset-result-info">
                                <p>
                                    You can now try and login with your new password!
                                </p>
                            </div>
                        </div>
                    </div>
            }
            </div>
        </div>
    )

}

export default PasswordReset;