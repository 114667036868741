import useRequestTracker from "../request/useRequestTracker";
import {useState} from "react";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import solrUpsellProductRequest from "../../rest/request/promotion/solrUpsellProductRequest";

const useWebUpsell = () => {

    const {
        loading,
        error,
        result,
        sendItWithTask
    } = useRequestTracker();

    const [ upsell, setUpsell ] = useState([]);

    const afterStart = (upsellItem) => {
        const startDate = upsellItem.startDate;
        if(startDate) {
            const now = new Date();
            return idempiereDate.getDate(startDate) < now;
        }
        return false;
    }

    const beforeEnd = (upsellItem) => {
        const endDate = upsellItem.endDate;
        if(endDate) {
            const now = new Date();
            return idempiereDate.getDate(endDate) > now;
        }
        return false;
    }

    const filterItem = (upsellItem) => {
        return afterStart(upsellItem) && beforeEnd(upsellItem);
    }

    const onUpsellReceived = (res) => {
        const upsellItems = [...res].filter(filterItem)
        const sorted = upsellItems.sort((a,b) => a.seqNo - b.seqNo);
        setUpsell(sorted);
    }

    if(!loading && !error && !result) {
        const req = solrUpsellProductRequest();
        sendItWithTask(() => req.sendRequest(), onUpsellReceived);
    }

    return {
        loading,
        error,
        promotions: upsell
    }

}

export default useWebUpsell;