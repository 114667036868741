import {condition, request} from "../../../res/rest/restRequest";
import {api} from "../../../res/rest/api";

export const getBankingRequest = (bpID) => {
    return request.model(api.MODEL.BANK_ACCOUNT)
        .expand(request.subQuery("x_mandate"))
        .filter(condition.eq("c_bpartner_ID", bpID))
        .filter(condition.eq("isActive", "true"));
}

export const getCreditStatusRequest = (bpID) => {
    return request.model(api.MODEL.C_BPARTNER)
        .select("SO_CreditLimit", "creditLimitCalc", "totalOpenBalance")
        .filter(condition.eq("c_bpartner_ID", bpID));
}

export const getActiveBackOrderRequest = (bpID) => {
    return request.model(api.MODEL.BACK_ORDER)
        .select("c_bpartner_location_ID", "c_order_ID", "c_orderLine_ID",
            "created", "minpoarrival", "MovementQty", "M_Product_ID", "orderedStockStatus",
            "c_uom_ID")
        .expand(request.subQueryList("M_Product_ID").select("Value", "Name", "imageurl"))
        .expand(request.subQuery("C_UOM_ID"))
        .expand(request.subQueryList("C_Order_ID").select("DocumentNo"))
        .filter(condition.eq("c_bpartner_ID", bpID));
}
