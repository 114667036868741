import useBackOrders from "../../../state/ordering/useBackOrders";
import {useState} from "react";
import LineBackOrder from "../ordering/LineBackOrder";
import HeaderSkeleton from "../ordering/HeaderSkeleton";
import {range} from "../../../../res/dataServices/range";

const AccountLayoutBackOrders = () => {

    const {
        backOrderLines,
        getActiveBackOrders,
        updatingLines,
        loading
    } = useBackOrders();

    const [reloaded, setReloaded] = useState(false);

    if(!reloaded) {
        setReloaded(true);
        getActiveBackOrders();
    }

    return (
        <>
            <h1>Back Orders</h1>
            {loading &&
                range(3).map(e => <HeaderSkeleton key={e}/>)
            }
            {backOrderLines && backOrderLines.length > 0 &&
                <div className="account-order-lines-display">
                    {backOrderLines.map((bo,i) =>
                        <LineBackOrder key={i} backOrderLine={bo} updatingLines={updatingLines} />
                    )}
                </div>
            }
            {!loading && (!backOrderLines || backOrderLines.length === 0) &&
                <div className="account-order-lines-display">
                    <p>You currently have no active back orders. If this doesnt seem right,
                        please get in contact via the help desk.</p>
                </div>
            }
        </>
    )
}

export default AccountLayoutBackOrders;
