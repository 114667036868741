import useAddress from "../../../state/account/useAddress";
import {Button} from "../../../general/input/Button";
import AddressDisplay from "../address/AddressDisplay";
import {useState} from "react";
import AddressInput from "../address/AddressInput";

const AccountLayoutLocations = () => {

    const { locations, updatingLocs, updateLocation, createLocation } = useAddress();
    const [ editState, setEditState ] = useState({ editing: false });

    const onEdit = (location) => {
        setEditState({
            editing: true,
            location: location,
        });
    }

    const onAddressUpdate = (location, changes) => {
        if(location) {
            updateLocation(location, changes);
        } else {
            createLocation(changes);
        }
    }

    const onInactivate = (location) => {
        updateLocation(location, { isActive: false });
    }

    const updating = updatingLocs && Object.keys(updatingLocs) > 0;
    if(updating && !editState.updating) {
        setEditState({ ...editState, updating: true });
    } else if(!updating && editState.updating) {
        setEditState({editing: false})
    }

    const activeLocations = locations ? locations.filter(loc => loc.IsActive) : null;

    return (
        <>
            <h1>Locations</h1>
            <div className="account-content-container">
                {!editState.editing &&
                    <>
                        <div className="address-display-container">
                            {activeLocations && activeLocations.map((e,i) =>
                                <AddressDisplay key={i} location={e} >
                                    <Button className="button_clear" onClick={() => onEdit(e)}>Update</Button>
                                </AddressDisplay>
                            )}
                        </div>
                        <Button onClick={() => onEdit()}>Add New Address</Button>
                    </>
                }
                {editState.editing &&
                    <AddressInput
                        location={editState.location}
                        onSave={onAddressUpdate}
                        onBack={() => setEditState({editing: false})}
                        onInactivate={onInactivate}
                        updating={editState.location ? updatingLocs[editState.location.id] : updatingLocs[0]}
                    />
                }
            </div>
        </>
    )

}

export default AccountLayoutLocations;
