
const reverseCategory = (parentID, category, reverseMap) => {
    reverseMap[category.id] = parentID;

    if(category.children) {
        for(const child of category.children) {
            reverseCategory(parentID, child, reverseMap);
        }
    }
}

export const getCategoryToParentMap = (categoryMap) => {
    const reversedMap = {};
    for(const categoryID of Object.keys(categoryMap)) {
        reverseCategory(categoryID, categoryMap[categoryID], reversedMap);
    }
    return reversedMap;
}

const formatCategoryList = (list, includeViewAll) => {
    const formatted = [];
    for(const category of list) {
        const clone = {...category};
        if(!clone.name) continue;

        clone.name = clone.name.replaceAll("/", "/ ")
        if(category.children) {
            clone.children = formatCategoryList(category.children);
            if(includeViewAll) {
                clone.children.unshift({name: "View All", id: category.id});
            }
        }
        formatted.push(clone);
    }
    formatted.sort((a,b) => a.name.localeCompare(b.name));
    return formatted;
}

const getCategoryList = (categoryMap, includeViewAll = true) => {
    if(!categoryMap) return [];

    const categories = Object.values(categoryMap);
    const formatted = formatCategoryList(categories, includeViewAll);
    if(includeViewAll) {
        formatted.unshift({name: "View All Categories", id: ""});
    }

    return formatted;
}

export const categoryService = {
    getCategoryList,
}