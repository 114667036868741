import PageLayout from "../../../../components/page/PageLayout";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import '../../style/page/productCategoryPage.css'
import '../../style/page/page.css'
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import React, {useMemo, useState} from "react";
import breadcrumb from "../../../logic/navigation/breadcrumb";
import Pagination from "../../navigation/Pagination";
import ProductLayout from "../../product/ProductLayout";
import ProductList from "../../product/ProductList";
import useCategoryPage from "../../../services/state/product/useCategoryPage";
import useSolrFilters from "../../../services/state/product/useSolrFilters";
import ProductFilter from "../../product/ProductFilter";
import FilterUrl from "../../../logic/filter/filterUrl";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {MobilePageNav, MobilePageNavItem} from "../../navigation/MobilePageNav";
import ReactiveNavContainer from "../../navigation/ReactiveNavDrawer";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";
import OrderBy from "../../../logic/filter/orderBy";

const getFilterUrl = (filter, filterParam) => {
    if(filter) {
        return "/" + filter;
    } else if(filterParam) {
        return "/" + filterParam;
    } else {
        return "";
    }
}

const PageProductCategory = () => {

    const navigate = useNavigate();

    const { categoryID : categoryIDParam, pageNo : pageNoParam, filter : filterParam } = useParams();
    const { categoryMap } = useSelector(
        state =>  state.category
    )
    const [ showFilterDrawer, setShowFilterDrawer ] = useState(false);

    FilterUrl.getMap(filterParam);

    const pageNo = pageNoParam && !isNaN(Number(pageNoParam)) ? Number(pageNoParam) : 1;
    const categoryID = Number(categoryIDParam);

    const {
        loading,
        products,
        pageCount
    } = useCategoryPage(pageNo, categoryID, filterParam);

    const {
        filters,
        getFilters
    } = useSolrFilters();

    useValueChangeListener(() => getFilters({categoryID}), [categoryID], [])
    useValueChangeListener(() => window.scrollTo(0, 0), [pageNo])

    const breadcrumbTrail = useMemo(
        () => breadcrumb.getBreadcrumbTrail(categoryMap, categoryID, categoryID, [{name: "Home", selected: false, nav: "/"}]),
        [categoryMap, categoryID]
    )

    usePageBreadcrumbTracker(breadcrumbTrail, [categoryID, pageNo, filterParam],
        () => `/category/${categoryID}/${pageNo}${(filterParam ? "/" + filterParam : "")}`);

    const onPageChange = (pageNo, filter) => {
        const url = "/category/" + categoryID + "/" + pageNo + getFilterUrl(filter, filterParam);
        navigate(url);
    }

    const onApply = (selectedFilters) => {
        const filter = FilterUrl.getUrl(selectedFilters);
        onPageChange(1, filter);
    }

    const filtersWithOrdering = filters ? {...filters, [OrderBy.ATTRIBUTE_SET.id]: OrderBy.FILTER} : null;

    return (
        <PageLayout
            headerChildren={
                <MobilePageNav>
                    <MobilePageNavItem onClick={() => setShowFilterDrawer(true)}>
                        Filter
                    </MobilePageNavItem>
                </MobilePageNav>
            }
        >
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <div className="product-layout-filters">
                        <ReactiveNavContainer
                            display={showFilterDrawer}
                            onHide={() => setShowFilterDrawer(false)}
                        >
                            <ProductFilter
                                filters={filtersWithOrdering}
                                onApply={onApply}
                                defaultParam={filterParam}
                                dependants={[categoryID]}
                            />
                        </ReactiveNavContainer>
                    </div>
                    <ProductList
                        products={products}
                        loading={loading}>
                    </ProductList>
                </ProductLayout>
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount - 1}
                    onPageRequested={onPageChange}
                    loading={loading}
                />
            </div>
        </PageLayout>
    )

}

export default PageProductCategory;