import useWebProduct from "../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../res/product/productPurchaseInfo";
import '../style/product/productDetail.css'
import useSolrBranding from "../../services/state/product/useSolrBranding";
import ProductInput from "./ProductInput";
import MapUtil from "../../logic/collection/mapUtil";
import BrandAccordion from "../brandBank/BrandAccordion";
import BrandLine from "../brandBank/BrandLine";
import BrandBankType from "../../logic/brandBank/brandBankType";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const getBrandingList = (productID, brandingMap) => {
    if(!brandingMap || !(productID in brandingMap)) {
        return [];
    } else {
        return MapUtil.mapToList(brandingMap[productID], (k,v) => ({groupName: k, lines: v}));
    }
}

const getHighlightTags = (brandingDetails) => {
    for(const group of brandingDetails) {
        if(group.groupName === "Manufacturers Details") {
            for(const line of group.lines) {
                if(line.brandbanktagname === "Product Marketing")
                    return [ line ];
            }
        }
    }
    return [];
}

const trackView = (product) => {
    if (product) {

        ReactGA.event('view_item', {
            value: product.getDisplayPrice(),
            currency: 'GBP',
            items: [
                {
                    item_id: product.getProductID(),
                    item_name: product.getName(),
                    price: product.getDisplayPrice(),
                }
            ]
        });
       
    }
}

const ProductDetail = ({ product }) => {

    const { webProduct } = useWebProduct({product});
    const displayProduct = ProductPurchaseInfo.getProductPageDisplayProduct(webProduct);
    const {
        loading,
        error,
        brandingMap,
        getBranding
    } = useSolrBranding();

    const sendBrandingRequest = () => {
        if(!loading && !error && product) {
            getBranding(product.id);
        }
    }

    useEffect(() => trackView(displayProduct), [displayProduct]);
    useValueChangeListener(sendBrandingRequest, product, [product]);

    const brandingDetails = getBrandingList(product.id, brandingMap);

    const justTheTypes = {};
    brandingDetails.forEach(d => d.lines.forEach(line => {
        const key = line.x_brandbank_quialifiedtagname;
        const types = MapUtil.computeIfAbsent(justTheTypes, key, () => []);
        types.push({ tag: line.x_tagname, bbTag: line.brandbanktagname, val: line.x_taghtml_content })
    }));

    return (
        <>
            <div className="prod-detail-container">
                <div className="prod-detail-head">
                    <p className="prod-detail-value">{displayProduct.getValue()}</p>
                    <p className="prod-detail-name">{displayProduct.getName()}</p>
                    <p className="prod-detail-uom">{displayProduct.getSelectedUom().name}</p>
                </div>
                <div className="prod-detail-img-container">
                    <img alt="" src={displayProduct.getImageUrl()} />
                </div>
                <div className="prod-detail-highlight">
                    {getHighlightTags(brandingDetails).map((l,i) =>
                        <BrandLine
                            key={i}
                            type={l.x_brandbank_quialifiedtagname}
                            tagName={l.x_tagname}
                            bbTagName={l.brandbanktagname}
                            content={l.x_taghtml_content}
                        />
                    )}
                </div>
                <div className="prod-detail-input">
                    {displayProduct.isBrochure() ?
                        <p>Please sign in to see prices.</p> :
                        <>
                            {displayProduct.getPromotionPrice() &&
                                <p className="prod-detail-price-pre-promo">{displayProduct.getPromotionPrice()}</p>
                            }
                            <p className="prod-detail-price">{displayProduct.getDisplayPrice()}</p>
                            <p className="prod-detail-price-tax">({displayProduct.getTaxedDisplayPrice()})</p>
                            <ProductInput product={displayProduct} />
                        </>
                    }
                </div>
            </div>
            <div className="prod-detail-branding-container">
                {brandingDetails.map(({groupName, lines}, i) =>
                    <BrandAccordion
                        header={groupName}
                        defaultExpanded={i === 0}
                        key={i}
                    >
                        {lines.sort(BrandBankType.sortLineByType).map((l,i) =>
                            <BrandLine
                                key={i}
                                type={l.x_brandbank_quialifiedtagname}
                                tagName={l.x_tagname}
                                bbTagName={l.brandbanktagname}
                                content={l.x_taghtml_content}
                            />
                        )}
                    </BrandAccordion>
                )}
            </div>
        </>
    )
}

export default ProductDetail;
