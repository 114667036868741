import {useReducer} from "react";

const STATUS_LOADING    = "loading";
const STATUS_ERROR      = "error";
const STATUS_COMPLETE   = "complete";

const getRequestState = (status, error) => {
    if(status === STATUS_LOADING) {
        return { loading: true,  error: false }
    } else if(status === STATUS_ERROR) {
        return { loading: false, error: error }
    } else {
        return { loading: false, error: false }
    }
}

const requestReducer = (state, { key, status, error }) => {
    return {
        ...state,
        [key]: getRequestState(status, error)
    }
}

const useMultiRequestTracker = () => {

    const [ trackedRequests, dispatch ] = useReducer(requestReducer, {});

    const beforeRequest = (key) => {
        dispatch({key, status: STATUS_LOADING});
    }

    const afterFailure = (key, error) => {
        dispatch({key, status: STATUS_ERROR, error: error});
    }

    const afterSuccess = (key, onSuccess, result) => {
        dispatch({ key, status: STATUS_COMPLETE })
        if(onSuccess) onSuccess(result);
    }

    const sendItAsync = async (key, request, onSuccess) => {
        beforeRequest(key);
        try {
            const result = await request();
            afterSuccess(key, onSuccess, result);
        } catch (e) {
            afterFailure(key, e);
        }
    }

    const sendIt = (key, request, onSuccess) => {
        sendItAsync(key, request, onSuccess);
    }

    return {
        trackedRequests,
        sendIt
    }

}

export default useMultiRequestTracker;