import PageLayout from "../../../../components/page/PageLayout";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import ProductLayout from "../../product/ProductLayout";
import ProductFilter from "../../product/ProductFilter";
import ProductList from "../../product/ProductList";
import Pagination from "../../navigation/Pagination";
import {useNavigate, useParams} from "react-router-dom";
import useSearch from "../../../services/state/search/useSearch";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";

const PAGE_SIZE = 20;

const PageSearch = () => {

    const navigate = useNavigate();
    const { searchTerm, pageNo : pageNoPara } = useParams();
    const breadcrumbTrail = [
        {name: "Home", nav: "/"},
        {name: "Search", nav: "/search/" + searchTerm, selected:false},
        {name: searchTerm, nav: "/search/" + searchTerm, selected:true}
    ]
    const pageNo = isNaN(Number(pageNoPara)) ? 1 : Number(pageNoPara);

    const {
        search,
        loading,
        results,
        count
    } = useSearch();

    useValueChangeListener(() => search(searchTerm, pageNo, PAGE_SIZE), [ searchTerm, pageNo ], [ ]);
    useValueChangeListener(() => window.scrollTo(0,0), [ searchTerm, pageNo ]);
    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo, searchTerm], () => `/search/${searchTerm}/${pageNo}`)

    const justTheProducts = results ? results.filter(res => res.isProduct) : null;
    const pageCount = Math.ceil(count / PAGE_SIZE);

    const onPageChange = (pageNo) => {
        navigate("/search/" + searchTerm + "/" + pageNo)
    }

    return (
        <PageLayout>
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <div className="product-layout-filters">
                        <ProductFilter
                            filters={{}}
                        />
                    </div>
                    <ProductList
                        products={justTheProducts}
                        loading={loading}
                    />
                </ProductLayout>
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount}
                    onPageRequested={onPageChange}
                />
            </div>
        </PageLayout>
    )

}

export default PageSearch;
