import axios from "axios";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import {request} from "../../../../../res/rest/restRequest";

const helpDeskCreateRequestRequest = () => {

    const req = request.model(api.MODEL.REQUEST);

    const data = {};
    const builder = {};

    builder.setRequestTypeID = (requestID) => {
        data["R_RequestType_ID"] = {
            "id" : requestID,
            "tableName": "R_RequestType"
        };
        return builder;
    }

    builder.setSummary = (summary) => {
        data.summary = summary;
        return builder;
    }

    builder.setConfidentialityType = (type) => {
        data.confidentialTypeEntry = type;
        return builder;
    }

    builder.setRoleID = (roleID) => {
        data.AD_Role_ID = roleID;
        return builder;
    }

    builder.setSalesRepID = (salesRepID) => {
        data.SalesRep_ID = salesRepID;
    }

    const prepareData = () => {
        if(!data.confidentialTypeEntry)
            data.confidentialTypeEntry = 'I';
        if(!data.AD_Role_ID)
            data.AD_Role_ID = 1000016;
        if(!data.SalesRep_ID)
            data.SalesRep_ID = 1000003;
    }

    builder.sendIt = async(session) => {
        prepareData();
        const url = req.buildRequest(env.API_URL);
        const resp = await axios.post(url, data, api.auth(session));
        return resp.data;
    }

    return builder;

}

export default helpDeskCreateRequestRequest;