import '../style/vendor/purchaseOrderLine.css'

const PurchaseOrderLine = ({ line }) => {

    return (
        <div className="purchase-order-line-container purchase-order-line">
            <p>{line.getLineNo()}</p>
            <p>{line.getProductCode()} {line.getProductName()}</p>
            <p>{line.getQuantityEntered()} </p>
            <p>{line.getUomName()}</p>
            <p>£{line.getLineNetAmt()}</p>
        </div>
    )

}

export default PurchaseOrderLine;