import {createSlice} from "@reduxjs/toolkit";
import {extendBuilder} from "../setup/reduxRequestHandler";
import {getUsualsOverview} from "../action/usualsActions";

const initialState = {
    loading: {},
    error: {},

    usuals: null,
    products: {},
}

const buildExtraReducers = (builder) => {

    extendBuilder(builder)
        .addStandardCase(getUsualsOverview, "usualsOverview", (state, {payload}) => {
            state.usuals = payload.usuals;
        })

}


export const usualsSlice = createSlice({
    name: 'slice',
    initialState,
    reducers: {
        resetUsuals: (state) => {
            state.usuals = null;
            state.products = {};
        }
    },
    extraReducers: buildExtraReducers
})

export const { resetUsuals } = usualsSlice.actions;
export default usualsSlice.reducer;