import {useDispatch, useSelector} from "react-redux";
import {getBanking} from "../../../redux/action/accountActions";

const useBanking = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(
        state => state.auth
    )
    const { banking, loading, error } = useSelector(
        state => state.account
    )

    if(!banking && !loading.banking && !error.banking) {
        dispatch(getBanking({session}))
    }

    return {
        banking,
        loading,
        error,
        loadingBanking: loading.banking,
        errorBanking: error.banking,
    }

}

export default useBanking;