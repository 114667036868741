import {Button} from "../general/input/Button";
import {useNavigate} from "react-router-dom";

const FeaturePromoQuickEntry = () => {

    const navigate = useNavigate();
    const redirectToQuickEntry = () => {
        navigate("/orderEntry")
    }

    return (
        <div className="feature-promo">
            <div className="feature-promo-content">
                <h1>In a Hurry?<br/>Try our Order Entry</h1>
                <p>Our new quick entry gives you access to all your regular products in one location.
                    Along with a quick search feature, to grab any other products you may want.
                    It’s the fastest way to order the stuff you need for your business!</p>
                <Button onClick={redirectToQuickEntry}>Try It Now</Button>
            </div>
            <div className="feature-promo-img-container">
            </div>
        </div>
    )

}

export default FeaturePromoQuickEntry;
