import PageLayout from "../../../../components/page/PageLayout";
import {useParams} from "react-router-dom";
import useProductIDPage from "../../../services/state/product/useProductIDPage";
import ProductDetail from "../../product/ProductDetail";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import React from "react";
import breadcrumb from "../../../logic/navigation/breadcrumb";
import {useSelector} from "react-redux";
import useStoredBreadcrumb from "../../../services/state/navigation/useStoredBreadcrumb";

const useProductBreadcrumb = (productID, product) => {

    const { categoryMap } = useSelector(
        state =>  state.category
    )
    const {
        getBreadcrumb
    } = useStoredBreadcrumb();

    const productName = product ? product.name : "loading";
    const productNav = {name: productName, selected: true, nav: "/product/" + productID}

    const storeBreadcrumb = getBreadcrumb();
    if(storeBreadcrumb) {
        storeBreadcrumb.push(productNav);
        return storeBreadcrumb;
    } else if(product) {
        const navHome = [{name: "Home", selected: false, nav: "/"}];
        const categoryID = product.categoryID;
        const categoryBreadcrumb = breadcrumb.getBreadcrumbTrail(categoryMap, categoryID, -1, navHome);
        categoryBreadcrumb.push(productNav);
        return categoryBreadcrumb;
    } else {
        return [];
    }
}

const PageProduct = () => {

    const { productID } = useParams();

    const {
        products
    } = useProductIDPage(1, [productID]);

    const product = products?.length > 0 ? products[0] : null;
    const breadcrumbTrail = useProductBreadcrumb(productID, product);

    return (
        <PageLayout>
            <div className="page-content page-min-height">
                <BreadcrumbNavigation>
                {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                )}
                </BreadcrumbNavigation>
                {product &&
                    <ProductDetail product={product} />
                }
            </div>
        </PageLayout>
    )

}

export default PageProduct;