import {useState} from "react";

const dependantsTheSame = (storedDependants, dependants) => {
    if(!storedDependants !== !dependants) return false;

    if(!storedDependants && !dependants) return true;

    if(storedDependants.length !== dependants.length) return false;

    for(const i in storedDependants) {
        const firstDependant    = storedDependants[i];
        const secondDependant   = dependants[i];
        if(firstDependant !== secondDependant)
            return false;
    }

    return true;
}

const useValueChangeListener = (runOnChange, dependants, defaultDependants) => {

    const initialDependants = defaultDependants ? defaultDependants : dependants;
    const [ storedDependants, setStoredDependants ] = useState(initialDependants);

    if(!dependantsTheSame(storedDependants, dependants)) {
        setStoredDependants(dependants);
        runOnChange();
    }

}

export default useValueChangeListener;
