import {condition, request} from "../../../res/rest/restRequest";
import {api} from "../../../res/rest/api";

export const getBaseUserRequest = () => {
    return request.model(api.MODEL.AD_USER)
        .expand(request.subQueryList("R_ContactInterest"));
}

export const getAUserRequest = (userID) => {
    return getBaseUserRequest()
        .filter(condition.eq("AD_User_ID", userID));
}

export const getBPartnerUsers = (bPartnerID) => {
    return getBaseUserRequest()
        .filter(condition.eq("C_BPartner_ID", bPartnerID));
}
