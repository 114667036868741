import {useSelector} from "react-redux";
import AccountNavContainer from "./AccountNavContainer";

const AccountNavHeader = () => {

    const { session } = useSelector(
        state => state.auth
    )

    const businessName = session.businessName;
    const userName = session.userName;

    return (
        <AccountNavContainer className="account-header-3">
            <h2>Business</h2>
            <h1>{businessName}</h1>
            <h2>User</h2>
            <h1>{userName}</h1>
        </AccountNavContainer>
    )

}

export default AccountNavHeader;