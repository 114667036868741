import {useDispatch, useSelector} from "react-redux";
import {getActiveBackOrders} from "../../../redux/action/accountActions";

const useBackOrders = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(
        state => state.auth
    )
    const { loading, backOrderLines } = useSelector(
        state => state.account
    )

    const getBackOrders = () => {
        dispatch(getActiveBackOrders({ session }));
    }

    const updatingLines = {};
    for(const loadingKey of Object.keys(loading)) {
        const keyPrefix = "bo_cancel_";
        if(loading[loadingKey] && loadingKey.startsWith(keyPrefix)) {
            updatingLines[Number(loadingKey.slice(keyPrefix.length))] = true;
        }
    }

    return {
        getActiveBackOrders: getBackOrders,
        backOrderLines,
        updatingLines,
        loading: loading["backOrders"]
    }

}

export default useBackOrders;