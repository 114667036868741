import {condition} from "../rest/restRequest";

const applyToRequest = (request, filters) => {
    for(const filterID of Object.keys(filters)) {
        const selection = filters[filterID].name.replace("&","and");
        const queryFilter = `{${filterID}:${selection}}`;
        request.filter(condition.contains("filter_list", queryFilter));
    }
}

export const requestFilter = {
    applyToRequest
}