import {listToString} from "../dataServices/string";

const API_SELECT = "$select=";
const API_EXPAND = "$expand=";
const API_FILTER = "$filter=";
const API_TOP    = "$top=";
const API_SKIP   = "$skip=";
const API_ORDER  = "$orderby=";

const SEPARATOR_QUERY       = "&";
const SEPARATOR_SUB_QUERY   = ";";

const getPoSelect = (request, isSubQuery) => {
    if(!isSubQuery && request.id)
        return request.model + "/" + request.id

    if(isSubQuery && request.expansionColumn) {
        return request.model + "." + request.expansionColumn;
    } else {
        return request.model;
    }
}

const addParamList = (params, paramKey, list, separator) => {
    if(list && list.length > 0) {
        const paramAsStr = paramKey + listToString(list, separator);
        params.push(paramAsStr)
    }
}

const addParam = (params, paramKey, item) => {
    if(item) {
        params.push(paramKey + item);
    }
}

const getExpansionList = (toExpand) => {
    const expandParam = [];
    for(const expansion of toExpand) {
        const asStr = (typeof expansion === "string") ? expansion :
            expansion.buildRequest();
        expandParam.push(asStr);
    }
    return expandParam;
}

const getOpenQueryItem = (params, isSubQuery, isList) => {
    if(params.length === 0) {
        return "";
    } else if(isSubQuery) {
        return isList ? "(" : "(?"
    } else {
        return "?";
    }
}

const getCloseQueryItem = (params, isSubQuery) => {
    if(params.length === 0 || !isSubQuery) return "";
    else return ")";
}

const buildModel = (request, isSubQuery, isList) => {
    const poSelect = getPoSelect(request, isSubQuery);

    const params = [];

    addParamList(params, API_SELECT, request.toSelect, ",");
    addParamList(params, API_FILTER, request.toFilter, " AND ");
    addParam(params, API_TOP, request.top);
    addParam(params, API_SKIP, request.skip);
    addParam(params, API_ORDER, request.orderBy);

    if(request.toExpand) {
        const expansions = getExpansionList(request.toExpand)
        addParamList(params, API_EXPAND, expansions, ",")
    }

    return poSelect
        + getOpenQueryItem(params, isSubQuery, isList)
        + listToString(params, !isSubQuery ? SEPARATOR_QUERY : SEPARATOR_SUB_QUERY)
        + getCloseQueryItem(params, isSubQuery);
}

export const modelBuilder = {
    buildModel
}